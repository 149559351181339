import { supabase } from "../../../utils/supabaseclient";

export const handleSubmit = async ({ e, user, inputTG, inputAddress, referrerTG, setTelegram, setEthAddress, setNeedsInfo }) => {
    e.preventDefault();

    // Validate input
    if (!inputTG || !inputAddress) {
        alert("Please fill out both fields.");
        return;
    }

    if (inputAddress.length < 42) {
        alert("Invalid ETH address!");
        return;
    }

    if (inputTG.length === 0 || inputTG.charAt(0) !== '@') {
        alert("Invalid Telegram username. It should start with '@'.");
        return;
    }

    // Step 1: Lookup Referrer ID if provided
    let referrerId = null;

    if (referrerTG && referrerTG.charAt(0) === '@') {
        console.log("Looking up referrer:", referrerTG);

        const { data: referrerData, error: referrerError } = await supabase
            .from("contributors")
            .select("id")
            .eq("telegram", referrerTG)
            .maybeSingle();

        if (referrerError) {
            console.error("Database error fetching referrer:", referrerError);
        } else if (!referrerData) {
            console.error("Referrer not found:", referrerTG);
        } else {
            referrerId = referrerData.id;
            console.log("Referrer ID found:", referrerId);
        }
    }

    // Step 2: Upsert user data with referral ID
    const { error: updateError } = await supabase
        .from("contributors")
        .upsert({
            id: user.id,
            telegram: inputTG,
            eth_address: inputAddress,
            referred_by: referrerId
        });

    if (updateError) {
        console.error("Error updating user data", updateError);
        alert("Failed to update information. Try again.");
    } else {
        console.log("User updated successfully!");
        setTelegram(inputTG);
        setEthAddress(inputAddress);
        setNeedsInfo(false);
    }
};
import { supabase } from "../../../../utils/supabaseclient";

export const approveWriteup = async ({ adminId, contributorId, taskId, multiplier, fetchWriteups }) => {
    if (multiplier < 1.0 || multiplier > 1.5) {
        alert("Multiplier must be between 1.0 and 1.5");
        return;
    }

    // Fetch the task_instance_id before calling the RPC
    const { data: instanceData, error: fetchError } = await supabase
        .from("claimed_tasks")
        .select("task_instance_id")
        .eq("contributor_id", contributorId)
        .eq("task_id", taskId)
        .single();

    if (fetchError || !instanceData) {
        alert("Error fetching task instance ID.");
        console.error(fetchError);
        return;
    }

    const taskInstanceId = instanceData.task_instance_id;

    // Call the RPC function with the retrieved task_instance_id
    const { data, error: rpcError } = await supabase.rpc("approve_online_link", {
        p_admin_id: adminId,
        p_contributor_id: contributorId,
        p_task_id: taskId,
        p_task_instance_id: taskInstanceId, // Ensure task instance is passed
        p_multiplier: multiplier
    });

    console.log(data);

    if (rpcError) {
        alert("Error approving writeup.");
        console.error(rpcError);
    } else {
        alert(`Writeup approved with a ${multiplier}x multiplier.`);
        fetchWriteups();
    }
};

import { supabase } from "../../../utils/supabaseclient";

export const declineInvite = async ({ inviteeId, inviteId, taskInstanceId, fetchInvites }) => {
    const { data, error } = await supabase.rpc("decline_task_invite", {
        p_invitee_id: inviteeId,
        p_invite_id: inviteId,
        p_task_instance_id: taskInstanceId
    });

    if (error) {
        console.error("Error declining invite:", error);
        alert("Failed to decline invite. Please try again.");
        return;
    }

    alert("Invite declined successfully.");
    
    // Optionally refresh invites list
    if (fetchInvites) {
        fetchInvites();
    }
};
import { supabase } from "../../../utils/supabaseclient";

export const fetchCurrentSprint = async ({ setSprint }) => {
    let { data, error } = await supabase
        .from('system_settings')
        .select('sprint')
        .single(); // Assumes only one row exists in system_settings

    if (error) {
        console.error("Error fetching sprint:", error);
        return;
    }

    setSprint(data.sprint);
};
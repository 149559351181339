import { supabase } from "../../../utils/supabaseclient";

export const fetchTaskInvites = async ({ userId, setTaskInvites }) => {
    const { data, error } = await supabase
        .from("task_invitations")
        .select(`
            id, 
            task_id, 
            inviter_id, 
            status,
            invited_at,
            tasks (
                title
            )
        `)
        .eq("invitee_id", userId)
        .eq("status", "pending");

    if (error) {
        console.error("Error fetching task invites:", error);
        return;
    }

    setTaskInvites(data);
};
import cpage from './contributorpage.module.css';
import { Link } from 'react-router-dom';
import '../../css/TextStyles.css'

export default function ContributorPageHeader()
{
    return(
        <div className = {cpage.HomeContainer}>

            <Link style = {{textDecoration: 'none'}} to = "/">
                <h3 className = {`${cpage.Home} ${cpage.H3} ${cpage.H3H}`}>Home</h3>
            </Link>

            <Link style = {{textDecoration: 'none'}} to = "/contribute">
                <h3 className = {`${cpage.Home} ${cpage.H3} ${cpage.H3H}`}>Contribute</h3>
            </Link>

            <Link style = {{textDecoration: 'none'}} to = "/docs">
                <h3 className = {`${cpage.Home} ${cpage.H3} ${cpage.H3H}`}>Docs</h3>
            </Link>

            <Link style = {{textDecoration: 'none'}} to = 
                    {{
                        pathname: '/docs',
                        search: '?section=2.1',
                        state: {section: 2.1}
                    }}>
                <h3 className = {`${cpage.Home} ${cpage.H3} ${cpage.H3H}`}>Examples</h3>
            </Link>

            <h3 
                style = {{cursor: "pointer"}} 
                onClick={() => 
                ('https://github.com/wisecameron/honeybadgerframework', '_blank')} 
                className = {`${cpage.Home} ${cpage.H3} ${cpage.H3H}`}
                >Github</h3>

            <h3 
                style = {{cursor: "pointer"}} 
                onClick={() => window.open('https://ethereum-magicians.org/t/erc-7844-consolidated-dynamic-storage-cds/22217', '_blank')} 
                className = {`${cpage.Home} ${cpage.H3} ${cpage.H3H}`}
                >ERC-7844</h3>
                
        </div>
    )
}
import { supabase } from "../../../utils/supabaseclient";

export const fetchTasks = async ({setLoading, setTasks, group, subGroup}) => {
    setLoading(true);

    let query = supabase
        .from('tasks')
        .select('*')
        .neq('group_identifier', 'custom'); // Exclude custom tasks

    if (group) query = query.eq('group_identifier', group);
    if (subGroup) query = query.eq('sub_identifier', subGroup);

    const { data, error } = await query;

    if (error) {
        console.error('Error fetching tasks:', error);
        setTasks([]);
    } else {
        setTasks(data);
    }

    setLoading(false);
};
import { supabase } from "../../../utils/supabaseclient";

export const fetchUser = async ({setUserId, fetchCurrentTask, setCurrentTask}) => {
    const { data, error } = await supabase.auth.getUser();
    if (error) {
        console.error('Error fetching user:', error);
        return;
    }
    const id = data?.user?.id;

    setUserId(id);
    fetchCurrentTask({userId: id, setCurrentTask});
};
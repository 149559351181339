import { supabase } from '../../../../utils/supabaseclient';
import { fetchTasks } from './fetchTasks';

export const fetchUser = async ({setIsAdmin, setLoading, navigate, setTasks, setFilteredTasks, setGroups, setAdminId}) => {
    const { data, error } = await supabase.auth.getUser();
    if (error || !data?.user) {
        navigate('/');  // Redirect non-logged-in users
        return;
    }

    const { data: userData } = await supabase
        .from('contributors')
        .select('is_admin')
        .eq('id', data.user.id)
        .single();

    if (userData?.is_admin) {
        setIsAdmin(true);
        setAdminId(data.user.id)
        fetchTasks({setTasks, setFilteredTasks, setGroups})
    } else {
        navigate('/');  // Redirect non-admins
    }
    setLoading(false);
};
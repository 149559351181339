import { supabase } from "../../../utils/supabaseclient";

export const submitOnlineLink = async ({ userId, currentTask, onlineSubmissionLink, setOnlineSubmissionLink, setUpdate, update }) => {
    if (!onlineSubmissionLink.trim()) {
        alert("Please enter a valid online submission link.");
        return;
    }

    // Call the RPC function instead of directly updating the database
    const { data, error } = await supabase.rpc("submit_online_link", {
        p_contributor_id: userId,
        p_task_id: currentTask.id,
        p_task_instance_id: currentTask.instanceId, // Ensure this is passed correctly
        p_submission_link: onlineSubmissionLink
    });

    alert(data)

    if (error) {
        alert(error.message || "Error submitting online writeup.");
        console.error("Submit online writeup error:", error);
        return;
    }

    alert("Online writeup submitted successfully.");
    setOnlineSubmissionLink("");
    setUpdate(update + 1);
};
import { useEffect, useState } from 'react';
import up from '../css/userPage.module.css';

export default function OptionContainer({
    currentTask, 
    setCurrentTask, 
    submitTask, 
    submissionLink, 
    setSubmissionLink, 
    userId,
    update, 
    setUpdate,
    cancelTask,
    inviteUsername,
    setInviteUsername,
    sendInvite,
    sentInvites,
    submitOnlineLink 
}) {
    const [onlineSubmissionLink, setOnlineSubmissionLink] = useState("");

    return (
        <div className={up.OptionContainer}>
            <h2>Current Task</h2>
            {currentTask ? (
                <>
                    <p>{currentTask.title}</p>
                    
                    {/* Task Submission */}
                    <form onSubmit={(e) => {submitTask({e, submissionLink, userId, currentTask, setSubmissionLink, setCurrentTask, setUpdate, update})}}>
                        <input
                            type="text"
                            placeholder="Submission Link"
                            value={submissionLink}
                            onChange={(e) => setSubmissionLink(e.target.value)}
                        />
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "20px"}}>
                            <button type="submit">Submit Task</button>
                            <button type="button" onClick={() => {cancelTask({currentTask, userId, setCurrentTask, setUpdate, update})}}>Cancel Task</button>
                        </div>
                    </form>

                    {/* Invite Section */}
                    <h3 style={{color: 'white', margin: 0, marginTop: "20px"}}>Invite Collaborators</h3>
                    <input
                        type="text"
                        placeholder="Enter Telegram Username"
                        value={inviteUsername}
                        onChange={(e) => setInviteUsername(e.target.value)}
                    />
                    <button onClick={() => {sendInvite({inviteUsername, setInviteUsername, setUpdate, update, userId, currentTask})}}>Send Invite</button>

                    {sentInvites.length > 0 && (
                        <div>
                            <h4 style={{color: "white"}}>Pending Invites:</h4>
                            <ul>
                                {sentInvites.map((invite, index) => (
                                    <li key={`${invite.invitee_id}-${index}`}>{invite.contributors?.telegram || "No Telegram"}</li>
                                ))}
                            </ul>
                        </div>
                    )}

                    <h3 style={{color: 'white', marginTop: "20px"}}>Submit Online Writeup (1.1 - 1.5x Points!)</h3>
                    <input
                        type="text"
                        placeholder="Enter Online Submission Link"
                        value={onlineSubmissionLink}
                        onChange={(e) => setOnlineSubmissionLink(e.target.value)}
                    />
                    <button onClick={() => { 
                        submitOnlineLink({ 
                            userId, 
                            currentTask, 
                            onlineSubmissionLink, 
                            setOnlineSubmissionLink, 
                            setUpdate, 
                            update 
                        }); 
                    }}>
                        Submit Online Writeup 
                    </button>
                </>
            ) : (
                <p>No active task.</p>
            )}
        </div>
    );
}
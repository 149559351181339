import { supabase } from "../../../utils/supabaseclient";

// Fetch referrer
export const fetchReferrer = async (userId, setReferrer) => {
    const { data, error } = await supabase
        .from("contributors")
        .select("referred_by")
        .eq("id", userId)
        .single();

    if (error) {
        console.error("Failed to fetch referrer:", error);
        return;
    }

    if (data?.referred_by) {
        // Get referrer's Telegram username
        const { data: referrerData, error: referrerError } = await supabase
            .from("contributors")
            .select("telegram")
            .eq("id", data.referred_by)
            .single();

        if (referrerError || !referrerData) {
            console.error("Failed to fetch referrer username:", referrerError);
        } else {
            setReferrer(referrerData.telegram);
        }
    }
};

// Set referrer
export const setReferrer = async (userId, referrerTG, setReferrer) => {
    // Find referrer's ID by Telegram username
    const { data: referrerData, error: referrerError } = await supabase
        .from("contributors")
        .select("id")
        .eq("telegram", referrerTG)
        .single();

    if (referrerError || !referrerData) {
        console.error("Invalid referrer username:", referrerError);
        return;
    }

    const referrerId = referrerData.id;

    // Update user's referred_by field
    const { error: updateError } = await supabase
        .from("contributors")
        .update({ referred_by: referrerId })
        .eq("id", userId);

    if (updateError) {
        console.error("Failed to set referrer:", updateError);
        return;
    }

    setReferrer(referrerTG); // Update state
};
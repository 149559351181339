import { supabase } from "../../../utils/supabaseclient";

export const fetchSprintPoints = async ({ userId, setCurrentSprintPoints, setTotalSprintPoints }) => {
    if (!userId) return;

    try {
        // Fetch the current sprint number from system_settings
        const { data: systemData, error: systemError } = await supabase
            .from('system_settings')
            .select('sprint')
            .single();

        if (systemError) {
            console.error("Error fetching current sprint:", systemError);
            return;
        }

        const currentSprint = systemData.sprint;

        // Fetch sprint points for the current sprint
        const { data: sprintData, error: sprintError } = await supabase
            .from('sprint_points')
            .select('points')
            .eq('contributor_id', userId)
            .eq('sprint_number', currentSprint);

        if (sprintError) {
            console.error("Error fetching sprint points:", sprintError);
            return;
        }

        // Fetch total sprint points from contributors table
        const { data: contributorData, error: contributorError } = await supabase
            .from('contributors')
            .select('total_sprint_points')
            .eq('id', userId)
            .single();

        if (contributorError) {
            console.error("Error fetching total sprint points:", contributorError);
            return;
        }

        // Sum all points for this sprint
        const sprintTotal = sprintData.reduce((acc, row) => acc + row.points, 0);
        
        // Extract total sprint points from contributors table
        const totalSprintPoints = contributorData?.total_sprint_points || 0;

        // Update state
        setCurrentSprintPoints(sprintTotal);
        setTotalSprintPoints(totalSprintPoints);
    } catch (error) {
        console.error("Unexpected error fetching sprint points:", error);
    }
};
import { fetchTasks } from "./fetchTasks";
import { supabase } from "../../../../utils/supabaseclient";

export const createTask = async ({e, title, description, points, group, subGroup, limit, setTasks, setFilteredTasks, setGroups}) => {
    e.preventDefault();
    if (!title || !description || points <= 0 || !group || !subGroup) {
        alert('Please fill out all fields correctly.');
        return;
    }
    const { error } = await supabase.from('tasks').insert([
        { title, description, points, group_identifier: group, sub_identifier: subGroup, completions_limit: limit }
    ]);
    if (error) {
        alert('Failed to create task.');
    } else {
        alert('Task created successfully!');
        fetchTasks({setTasks, setFilteredTasks, setGroups})
    }
};
import '../../../../css/keyFonts.css';
import styles from '../Common/page.module.css';
import PageLayout from '../Common/PageLayout';
import {P, H, Tab, Ul, Li, Sm, BigLi, H2, S, White, P2} from '../Common/CommonElements'

import advancedDiagram from '../../../../assets/AdvancedDiagram.svg';
import upgradeStorage from '../../../../assets/upgradeStorage.svg'

export default function Page_7_1({mobileMode})
{
    return (
        <PageLayout mobileMode = {mobileMode}>
            <h2 className={styles.PageHeader}>
                HoneyBadger Documentation
            </h2>

            <P>
                Welcome to the docs!  This is where you can find guides, code examples, 
                and resources to help you build with HoneyBadger.
            </P>
            <S/>
            <S/>

            <div className={styles.Center} >
                <img className = {styles.Img2} src = {upgradeStorage} />
            </div>
            <p style = {{textAlign: 'center', color: 'white'}}>

                Upgrading storage structures in-place.

            </p>
            <S />
            <S />

            <H2>
                What is HoneyBadger?
            </H2>
            <P>

                HoneyBadger is a storage management system built in Solidity.  
                It addresses common pain points such as complex dependencies, lacking standardization, and
                siloed storage, while providing a cohesive supporting framework 
                designed to enhance development and upgrades, emphasizing 
                convenience, consistency, and safety.

                <br/><br/>

                A single HoneyBadger layer can support the evolving data needs of 
                an arbitrary-sized contract system.  Storage is segregated in 
                distinct "storage spaces" that provide access to instances of 
                custom "extendible structs."  Both data structures are fully managed 
                by HoneyBadger, allowing developers to create and extend data structures via deterministic function routines,
                without re-deployments.  These data structures are accessed 
                via a standardized interface, removing the need for putters, getters, and setters.

                <br/><br/>
                In practice, developers deploy a HoneyBadger instance, then configure its permissions and 
                storage spaces to match the needs of their logic layers: <i>init_create(types, sizes, specialAccessPatterns?)</i>, 
                <i> update_permissions(entity, flags, remove)</i>.  If they have advanced requirements 
                such as full decentralized governance or fine-grained access controls, they can leverage 
                specialized HoneyBadger models.  When they need to modify or retrieve data, they invoke 
                HoneyBadger functions such as <i>put(...)</i> and <i>get(...).</i>  It's different, 
                but it's also intuitive. 

            </P>
            <S />
            <S/>

            <S /><S /> <S />
            <div className={styles.Center} >
                <img className = {styles.Img2} src = {advancedDiagram} />
            </div>
            <p style = {{textAlign: 'center', color: 'white'}}>

                HoneyBadger is applicable to any protocol that stores data on-chain.

            </p>


        </PageLayout>
    )
}

{/* <P>
HoneyBadger is an on-chain storage management solution built in Solidity.  
It provides full support for the evolving data needs of arbitrary-sized smart contract systems, 
enabling a standardized data management interface built to facilitate 
effective development, interoperability, and upgrades.

<br/><br/>

HoneyBadger fully manages storage upgrades, abstracting away manual storage management in favor 
of deterministic function routines that shape the storage space in-place. 
Data is securely stored in hash-segregated storage spaces, which 
track indexed instances of custom "extendible struct" types.  Storage spaces are created
and extended via function calls, enabling full storage upgradeability that doesn't 
require re-deployments, can't mis-align storage, is colission-free, and is easily
managed by governance systems.

<br/><br/>

By consolidating data, HoneyBadger makes storage consistent: no more putters, getters, and adapters.  
Cross-contract dependencies are streamlined, and storage access controls are cohesively united within a 
single context.  The result is powerful upgradeability and future-proof infrastructure in a package 
that simplifies development, rather than making it more complex.

</P> */}
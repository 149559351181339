import { supabase } from "../../../utils/supabaseclient";

export const fetchUser = async({ setLoading, setUser, setNeedsInfo, setTelegram, setEthAddress }) => 
{
    const { data, error } = await supabase.auth.getUser();

    if(error)
    {
        console.error("Failed to get user", error);
        setLoading(false);
        return;
    }

    if(data?.user)
    {
        setUser(data.user);

        const { data: userData, error: fetchError } = await supabase
            .from("contributors")
            .select("telegram, eth_address") // Removed completed_orientation
            .eq("id", data.user.id)
            .single();

        if (fetchError || !userData || !userData.telegram || !userData.eth_address) 
        {
            setNeedsInfo(true);
        } else 
        {
            setTelegram(userData.telegram);
            setEthAddress(userData.eth_address);
            setNeedsInfo(false);
        }
    }
    else
    {
        setNeedsInfo(true);
    }

    setLoading(false);
};
import { supabase } from "../../../utils/supabaseclient";

export const fetchTaskContributors = async ({ userId, taskId, setTaskContributors, currentTask }) => {
    if (!taskId || !currentTask) return; 

    console.log(currentTask)

    const { data, error } = await supabase
        .from('claimed_tasks')
        .select(`
            contributor_id,
            contributors(telegram)
        `)
        .eq('task_id', taskId.id)
        .eq('task_instance_id', currentTask.instanceId)
        .neq('contributor_id', userId)  // 🔹 Exclude the current user
        .limit(1); 

    if (error) {
        console.error("Error fetching task contributors:", error);
        return;
    }
    
    setTaskContributors(data);
};

import { useEffect, useState } from 'react';
import TaskItem from './TaskItem';
import '../../css/TaskList.css';
import { fetchCurrentTask } from '../../../DBActions/fetchCurrentTask';
import { fetchUser } from '../../../DBActions/fetchUser';
import { fetchTasks } from '../../../DBActions/fetchTasks';
import { fetchGroups } from '../../../DBActions/fetchGroups';
import { handleClaim } from '../../../DBActions/handleClaim';
import { handleCreateCustomTask } from '../../../DBActions/handleCreateCustomTask';

export default function TaskList({ 
    group: initialGroup = null, 
    subGroup: initialSubGroup = null, 
    title, 
    update, 
    setUpdate,
    disableCustomTask = false,
    setUpdateTask
}) {
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentTask, setCurrentTask] = useState(null);
    const [userId, setUserId] = useState(null);
    const [group, setGroup] = useState(initialGroup);
    const [subGroup, setSubGroup] = useState(initialSubGroup);
    const [availableGroups, setAvailableGroups] = useState([]);
    const [availableSubGroups, setAvailableSubGroups] = useState([]);

    // Custom Task Fields
    const [customTitle, setCustomTitle] = useState("");
    const [customDescription, setCustomDescription] = useState("");

    useEffect(() => {
        async function loadData() {
            setLoading(true);
            await fetchUser({ setUserId, fetchCurrentTask, setCurrentTask });
            await fetchTasks({ setLoading, setTasks, group, subGroup });
            await fetchGroups({ setAvailableGroups });
            setLoading(false);
        }
        loadData();
    }, [group, subGroup, update]);

    useEffect(() => {
        if (tasks.length > 0) {
            if (group) {
                // Filter subgroups only for the selected group
                const subGroupsForGroup = tasks
                    .filter(task => task.group_identifier === group)
                    .map(task => task.sub_identifier)
                    .filter((value, index, self) => self.indexOf(value) === index); // Remove duplicates
                setAvailableSubGroups(subGroupsForGroup);
            } else {
                // If no group is selected, show all unique subgroups
                const allSubGroups = tasks
                    .map(task => task.sub_identifier)
                    .filter((value, index, self) => self.indexOf(value) === index); // Remove duplicates
                setAvailableSubGroups(allSubGroups);
            }
        } else {
            setAvailableSubGroups([]);
        }
    }, [tasks, group]);

    return (
        <div className='task-list-container'>
            <h2>{title}</h2>
            <p style = {{color: 'white', whiteSpace: 'pre-wrap', textAlign: 'center'}}>
            Project templates can define your full project scope, or just be a starting point - feel free to be creative and change the spec!  
            If you go above and beyond, we can always increase max points.
            </p>

            {/* Group & Subgroup Dropdown */}
            <div className="filter-container">
                <div className="group-filter">
                    <label htmlFor="group-select">Filter by Group:</label>
                    <select
                        id="group-select"
                        value={group || ''}
                        onChange={(e) => {
                            const selectedGroup = e.target.value || null;
                            setGroup(selectedGroup);
                            setSubGroup(null); // Reset subgroup selection when group changes
                            setUpdate(update + 1);
                        }}
                    >
                        <option value="">All Groups</option>
                        {availableGroups.map(g => (
                            <option key={g} value={g}>{g}</option>
                        ))}
                    </select>
                </div>

                <div className="subgroup-filter">
                    <label htmlFor="subgroup-select">Filter by Subgroup:</label>
                    <select
                        id="subgroup-select"
                        value={subGroup || ''}
                        onChange={(e) => {
                            const selectedSubGroup = e.target.value || null;
                            setSubGroup(selectedSubGroup);
                            setUpdate(update + 1);
                        }}
                    >
                        <option value="">All Subgroups</option>
                        {availableSubGroups.map(sg => (
                            <option key={sg} value={sg}>{sg}</option>
                        ))}
                    </select>
                </div>
            </div>


            {loading ? (
                <h3>Loading opportunities...</h3>
            ) : (
                <>
                    <div className='task-row'>
                        {tasks.map((task) => (
                            <TaskItem 
                                key={task.id} 
                                task={task} 
                                onClaim={() => {handleClaim({taskId: task.id, userId, currentTask, setCurrentTask, setUpdate, update, setUpdateTask})}} 
                                currentTask={currentTask} 
                            />
                        ))}
                    </div>

                    {!disableCustomTask && (
                        <div className="custom-task">
                            <h3>Create a Custom Project</h3>
                            <input 
                                type="text" 
                                placeholder="Title (max 100 chars)" 
                                value={customTitle} 
                                onChange={(e) => setCustomTitle(e.target.value)}
                                maxLength={100}
                            />
                            <textarea 
                                placeholder="Description (max 500 chars)" 
                                value={customDescription} 
                                onChange={(e) => setCustomDescription(e.target.value)}
                                maxLength={500}
                            />
                            <button onClick={() => {handleCreateCustomTask(
                                {
                                    userId, 
                                    currentTask,
                                    customTitle,
                                    customDescription,
                                    setCustomDescription,
                                    setCustomTitle,
                                    update,
                                    setUpdate 
                                })}}>Create Project</button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

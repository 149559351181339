import { Canvas } from '@react-three/fiber';
import MatrixRainingLetters from '../../../TestSpace/MatrixEffect2/MatrixEffect2';
import styles from '../CSS/Section3.module.css';
import HBPhase1 from '../../../Models/HoneyBadgerPhase1/HoneyBadgerModelPhase1';
import { NeoGlasses } from '../../../Models/NeoGlasses';
import '../../../../css/glowButton.css'
import { Link } from 'react-router-dom';

export default function Section3_HTML()
{

    let offsetX = 0;
    const width = window.innerWidth; 
    if(width < 950) offsetX = -2.5;
    else if(width < 1090) offsetX = -2;
    else if(width < 1230) offsetX = -1.5;
    else if(width < 1375) offsetX = -1;

    return(
        <div id = "S3Container" className= {styles.Container}>

            <div className={styles.MatrixContainer} id = "MatrixContainer">
                <MatrixRainingLetters />
            </div>

            <div className={styles.CanvasContainer} id = "S3Canvas">
                <Canvas style = {{width: "100vw", height: "100vh"}}>
                    <group position = {[offsetX, 0,0]}>
                        <NeoGlasses 
                            rotation = {[0, Math.PI, 0]}
                            position = {[6.2, -2.35, .5]}
                            scale = {.85}
                        />
                        <HBPhase1 
                            scale = {17.5} 
                            rotation = {[0, -Math.PI / 2, 0]}
                            position = {[12.5, 0, 0]}
                            />
                    </group>
                </Canvas>
            </div>

            <div className={styles.TextContainer} id = "S3Content">
                <div className={styles.TextContainerInner} id = "S3InnerTC">
                    <h2 id = "S3InitialText" className='titleText'>
                        Are you ready to go beyond the limits 
                        of blockchain?
                    </h2>
                    <div className={styles.ButtonContainer} id = "S3ButtonsContainer">
                        <a 
                        href="https://github.com/wisecameron/honeybadgerdemo" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        >
                            <button className = 'glowingButton2' 
                                style = {{background: "rgba(177, 93, 93, 0.8)"}}>
                                TRY THE DEMO VERSION 
                            </button>
                        </a>
                        <Link to = '/docs'>
                            <button className = 'glowingButton2'>
                                READ THE DOCS
                            </button>
                        </Link>
                    </div>

                </div>
            </div>


        </div>
    )
}
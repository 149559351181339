import '../../../../css/keyFonts.css';
import styles from '../Common/page.module.css';
import PageLayout from '../Common/PageLayout';
import {P, H, Tab, Ul, Li, Sm, BigLi, H2, S, White, P2} from '../Common/CommonElements'

import funs from '../../../../assets/funs.svg';
import { Link } from 'react-router-dom';


export default function Page_7_3({mobileMode})
{
    return (
        <PageLayout mobileMode = {mobileMode}>
            <h2 className={styles.PageHeader}>
                What Can You Build With HoneyBadger? 
            </h2>

            <P>
                HoneyBadger supports any system that stores data.  You can really build anything with 
                HoneyBadger that you could build <i>without </i> HoneyBadger: it's not restrictive.  

                <br/><br/>

                The question is really: <i>WHY is it worth it to learn this new paradigm?  
                What do I really get out of using this?  </i> 

                <br/><br/>

                HoneyBadger is the easiest 
                way to ensure that your smart contract system is future-proof, cost-effective, and implemented with 
                minimal extraneous complexity.  HoneyBadger's architecture cohesively unites best practices 
                in areas including data-access standardization, dependency management, upgrade management, 
                and access controls.  You don't have to do extra work to set everything up or to bring these 
                patterns to your contract: 
                initization only requires creating storage spaces and assigning permissions.  
                Ultimately, HoneyBadger doesn't take the center stage: it creates an environment that allows your idea 
                to naturally take shape 
                in a form that is scalable, efficient, and most 
                importantly, <i>convenient to you.</i>

            </P>

            <H2>
                Get Involved and Start Building With HoneyBadger
            </H2>
            <P>
                First, check out the "Using HoneyBadger" section: it'll give you code examples that 
                help bring everything together.  

                <br/><br/>
                Then, you might as well create a contributor account: if you build with HoneyBadger, 
                we'll recognize you as a contributor, promote your work, and even give you a stake 
                in the project.  

                <br/><br/>
                Just keep it simple at first: fork the ERC20 contract from utils and 
                add a simple staking feature, or try building an atomic swap that uses 
                HoneyBadger for storage.  You'll get it quickly, and at that point, 
                advanced applications will be intuitive.
            </P>
            <S />
            <S/>
            <div className = {styles.Col}>
                <Link to = "/contribute" style = {{textDecoration: "none"}}>
                    <button className = {styles.Button}>Contributor Program</button>
                </Link>
                <button className = {styles.Button}  
                onClick={() => { window.open("https://github.com/wisecameron/honeybadgerframework", "_blank", "noopener,noreferrer"); }}
                >Official Repo</button>
                <button 
                className = {styles.Button}
                onClick={() => { window.open("https://github.com/wisecameron/honeybadgerutils", "_blank", "noopener,noreferrer"); }}>Utils Repo</button>
            </div>

        </PageLayout>
    )
}
import { useEffect, useState } from 'react';
import styles from './Web3Interface.module.css';


export default function Web3Interface()
{
    const [CA, setCA] = useState('')

    useEffect(() => {
        console.log(document.body.scrollWidth, window.innerWidth);
    }, [])

    return(
        <div className = {styles.SC}>
            <div className = {styles.Container}>
                <h2 className = {styles.H2Main}>
                    HoneyBadger Dashboard
                </h2>

                <div className = {styles.LongContainer}>
                    <h2 className = {styles.H2}>
                        Use Existing Instance
                    </h2>
                    <form className = {styles.CAForm}>
                        <input 
                            type="text" 
                            value={CA} 
                            onChange={(e) => setCA(e.target.value)} 
                            placeholder="Enter Contract Address"
                            className={styles.Input}
                        />
                        <button>
                            Connect
                        </button>
                    </form>

                </div>

                <div className = {styles.LongContainer}>
                    <h2 className = {styles.H2}>
                        Deploy a New Instance
                    </h2>
                        <button className = {styles.Deploy}>
                            Deploy to Mainnet
                        </button>

                </div>

            </div>
        </div>
    )
}
import { supabase } from "../../../../utils/supabaseclient";

export const fetchPendingWriteups = async ({ setWriteups }) => {
    const { data, error } = await supabase
        .from("claimed_tasks")
        .select(`
            contributor_id,
            task_id,
            online_submission_link,
            contributors!claimed_tasks_contributor_id_fkey(telegram),
            tasks!inner(title)
        `)
        .eq("online_submission_approved", false)
        .eq("completed", false)
        .not("online_submission_link", "is", null);

    if (!error) {
        setWriteups(data);
    } else {
        console.error("Error fetching pending writeups:", error);
    }
};
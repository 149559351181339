import { supabase } from "../../../../utils/supabaseclient";


export const isValidTelegramUsername = (username) => {
    return /^@[a-zA-Z0-9](?:[a-zA-Z0-9_]{3,30}[a-zA-Z0-9])?$/.test(username);
};

export const TGUpdate = async ({inputTG, userId, setTelegram}) => {
    if (!isValidTelegramUsername(inputTG)) {
        alert("Invalid Telegram username! It must be 5-32 characters long, contain only letters, numbers, and underscores, and cannot start or end with an underscore.");
        return;
    }

    const { error } = await supabase
        .from("contributors")
        .update({ telegram: inputTG })
        .eq("id", userId);

    if (error) {
        console.error("Failed to update Telegram username", error);
        alert("Failed to update. Try again.");
    } else {
        alert("Telegram username updated successfully!");
        setTelegram(inputTG)
    }
};

export const ETHUpdate = async({inputETH, userId, setEthAddress}) => {
    if(inputETH.length != 42)
    {
        alert("Invalid ETH address!");
        return;
    }

    const { error } = await supabase
    .from("contributors")
    .update({ eth_address: inputETH })
    .eq("id", userId);

    if (error) {
        console.error("Failed to update Eth Address", error);
        alert("Failed to update. Try again.");
    } else {
        alert("Eth address updated successfully!");
        setEthAddress(inputETH)
    }
}
import '../../css/TextStyles.css'

import { useRef, useState, useEffect } from 'react';
import IntroScroll from './IntroScroll/IntroScroll';
import StoryBrandSectionMain from './StoryBrandSection/StoryBrandSectionMain';
import StoryBrandSectionWrapper from './StoryBrandSection/StoryBrandSectionWrapper';


export default function LowerScrollSection(props) 
{
    const [particleSceneInView, setParticleSceneInView] = useState(false)
    const [introContainerHeight, setIntroContainerHeight] = useState(0);
    const [retry, setRetry] = useState(0);

    const particleSceneRef = useRef();
    const introContainerRef = useRef();

    useEffect(() => {
        const updateHeight = () => {
            if (introContainerRef.current) {
                setIntroContainerHeight(introContainerRef.current.clientHeight + 5);
            }
        };

        window.addEventListener('resize', updateHeight);
        if (retry < 5) {
            setRetry(retry + 1);
        }

        if (retry >= 5) {
            updateHeight();
        }

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [retry]);

    useEffect(() => {
        if (!particleSceneRef.current) return;

        const observer = new IntersectionObserver(
            ([entry]) => {
                let intersecting = entry.isIntersecting;

                if (particleSceneInView !== intersecting) {
                    setParticleSceneInView(intersecting);
                }
            },           
            {
                rootMargin: '400px 0px' // Pre-render 200px before it enters the viewport
            },
            { threshold: 0.1 } // Adjust the threshold as needed
        );

        observer.observe(particleSceneRef.current);

        return () => {
            if (particleSceneRef.current) {
                observer.unobserve(particleSceneRef.current);
            }
        };
    }, [particleSceneInView]);


    return (
        <div className='LowerScrollContainer'>
            <div className='LowerScrollContainerInner'>
                {/* <IntroScroll /> */}
                <StoryBrandSectionWrapper>
                    <StoryBrandSectionMain />
                </StoryBrandSectionWrapper>
            </div>
        </div>
    )
}


import '../css/AdminDashboard.css'
import { filterTasksByGroup } from './filterTasksByGroup'

export default function DisplayFilterByGroup({
    setSelectedGroup,
    setFilteredTasks,
    tasks,
    selectedGroup,
    groups,
})
{
    return(
        <select 
            className='task-filter-dropdown' 
            value={selectedGroup} 
            onChange={(e) => filterTasksByGroup({group: e.target.value, setSelectedGroup, setFilteredTasks, tasks})}
        >
            <option value="All">All Groups</option>
            {groups.map(group => (
                <option key={group} value={group}>{group}</option>
            ))}
        </select>
    )
}
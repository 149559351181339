import up from '../css/userPage.module.css';

export default function TaskContributors({ taskContributors }) {
    return (
        <>
            {taskContributors.length > 0 && (
                <div className={up.ContributorsContainer}>
                    <h3 className={up.ContributorsHeading}>Task Contributors</h3>
                    <div className={up.ContributorsScroll}>
                        {taskContributors.map(({ contributor_id, contributors }, index) => (
                            <div className={up.ContributorCard} key={`${contributor_id}-${index}`}>
                                <p className={up.ContributorName}>
                                    {contributors.telegram || "Unknown"}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}

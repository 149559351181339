import { createTask } from "../../DBActions/Admin/createTask"
import '../css/AdminDashboard.css'

export default function TaskCreationForm({
    title,
    description, 
    points,
    group,
    subGroup,
    limit,
    setTasks,
    setFilteredTasks,
    setGroups,
    setTitle,
    setDescription,
    setPoints,
    setGroup,
    setSubGroup,
    setLimit
})
{
    return(
        <>
            <form onSubmit={(e) => {createTask({e, title, description, points, group, subGroup, limit, setTasks, setFilteredTasks, setGroups})}} className='task-form'>
                <label>Title:</label>
                <input type='text' value={title ?? ''} onChange={(e) => setTitle(e.target.value)} required />
                
                <label>Description:</label>
                <textarea value={description ?? ''} onChange={(e) => setDescription(e.target.value)} required></textarea>
                
                <label>Max Points:</label>
                <input type='number' value={points ?? ''} onChange={(e) => setPoints(Number(e.target.value))} required />
                
                <label>Group:</label>
                <input type='text' value={group ?? ''} onChange={(e) => setGroup(e.target.value)} required />
                
                <label>Sub Group:</label>
                <input type='text' value={subGroup ?? ''} onChange={(e) => setSubGroup(e.target.value)} required />
                
                <label>Completions Limit (optional):</label>
                <input type='number' value={limit ?? ''} onChange={(e) => setLimit(Number(e.target.value) || null)} />
                
                <button type='submit'>Create Task</button>
            </form>

            <hr style = {{width: "100%", border: "2px solid black"}}/>
        </>
    )
}
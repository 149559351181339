import { H, H2, Li, P, P2, S, Sm, Tab, Ul, White } from "../Common/CommonElements";
import PageLayout from "../Common/PageLayout";
import styles from '../Common/page.module.css';
import setPerms from '../../../../assets/carbon/SetPermissions.png'
import init from '../../../../assets/carbon/Init.png'
import push from '../../../../assets/carbon/push.png'
import pageStyles from '../Common/page.module.css';


export default function Page_2_1({mobileMode})
{
    return(
        <PageLayout mobileMode={mobileMode}>
            <h2 className={styles.PageHeader}>
                Setting Up Your HoneyBadger Instance: Basic Approach
            </h2>
            <P2>
                <Tab /> <b>1: Deployment</b>
                <br/><br/>
                <Tab/>
                The first step is to deploy your HoneyBadger contract to mainnet.  The only 
                constructor arg is the "owner" address: a user account with full permissions that 
                you will use to manage configuration.
            </P2>

            <P2>
                <br/><br/>
                <Tab /> <b>2: Setting Permissions</b>
                <br/><br/>
                <Tab/>
                You might want to initialize each contract's storage spaces algorithmically, 
                rather than sending each call manually.  This way, you can automate the entire 
                deployment process. To do this, we need to give those contracts authority to 
                modify the storage space.  If you want to give additional permissions, 
                this is a great time to do it.
            </P2>

            <img style = {{marginTop: "20px"}} className = {pageStyles.Img} src = {init} />

            <P2>
                <br/><br/>
                <Tab /> <b>3: Setting Up Storage Spaces</b>
                <br/><br/>
                <Tab/>
                    Now that our contracts have permissions, we can invoke an initialization function 
                    in each contract to initialize their storage spaces.  Best practice are to use globals to track 
                    storage space ids and enums to track fields, as this will significantly enhance the 
                    readability of our code.
            </P2>

            <img style = {{marginTop: "20px"}} className = {pageStyles.Img} src = {setPerms} />

            <P2>
                <br/><br/>
                <Tab /> <b>4: Allocating Storage</b>
                <br/><br/>
                <Tab/>
                    HoneyBadger can index storage spaces in a variety of ways. The most basic approach is via 
                    sequential uint indexing, mapping each instance within a storage space to a numerical value [1,2,3, ...]. 
                    Let's say we want to allocate data for globals.  We can invoke <i>push </i> with amount 1, as in the example below.

                    <br/><br/>
            </P2>

            <img style = {{marginTop: "20px"}} className = {pageStyles.Img} src = {push} />

        </PageLayout>
        
    )
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useRoutes
} from "react-router-dom";
import DocumentationPage from './components/DocumentationPage/DocumentationPage';
import TestSpace from './components/TestSpace/TestSpace';
import Experience from './components/Roadmap/Experience';
import { isDesktop, isMobile, isTablet } from 'react-device-detect';
import ContributorPage from './components/ContributorPage/ContributorPage';
import Audit from './components/ContributorPage/SupportingPages/Audit';
import Build from './components/ContributorPage/SupportingPages/Build';
import Web3Interface from './components/Web3Interface/Web3Interface';
import MicroTasks from './components/ContributorPage/SupportingPages/MicroTasks';
import AdminDashboard from './components/ContributorPage/SupportingPages/AdminDashboard';
import DeveloperDocs from './components/DeveloperDocs/DeveloperDocs';
import Academy from './components/ContributorPage/SupportingPages/Academy';

/*
        <Route path = "/get" element = {<InterfacePage />} />
        <Route path = "/docs" element = {<DocumentationPage />} />
*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <Router>
      <Routes>
        <Route path = "/" element = {(isDesktop) ? <App airdropOpen={false}/> : <DocumentationPage />} />
        <Route path = "/get" element = {<span />} />
        <Route path = "/docs" element = {<DocumentationPage />} />
        <Route path = "/test-space" element = {<TestSpace />} />
        <Route path = "/airdrop" element = {<App airdropOpen = {true}/>} />
        <Route path = "/contribute" element = {<ContributorPage />} />
        <Route path = "/interface" element = {<Web3Interface />} />
        <Route path = "/admin" element = {<AdminDashboard />} /> 
        <Route path = "/developerdocs" element = {<DeveloperDocs />} />
        <Route path = "/academy" element = {<Academy />} />
      </Routes>
    </Router>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

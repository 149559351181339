import { H, H2, Li, P, P2, S, Sm, Tab, Ul, White } from "../Common/CommonElements";
import PageLayout from "../Common/PageLayout";
import styles from '../Common/page.module.css';
import specialAccess from '../../../../assets/carbon/specialAccess.png'
import get from '../../../../assets/carbon/get.png'
import addressIndexing from '../../../../assets/carbon/addressIndexing.png';
import pageStyles from '../Common/page.module.css';


export default function Page_2_3({mobileMode})
{
    return(
        <PageLayout mobileMode={mobileMode}>
            <h2 className={styles.PageHeader}>
                Special Access Patterns
            </h2>
            <P2>
                <b>Address Indexing</b>
                <br/><br/>
                <Tab/>
                Address indexing allows you to map a user's numerical id to their 
                address.  It's recommended to configure address indexing in the same 
                sequence that initializes a user's data.  If a user doesn't have an id,
                push a new entry and assign an address index.
                <br/><br/>
            </P2>

            <img style = {{marginTop: "20px"}} className = {pageStyles.Img} src = {addressIndexing} />

            <P2>
                <br/><br/>
                <b>Special Access Patterns</b>
                <br/><br/>
                <Tab/>
                Address indexing is nice, but what happens when we want to used complex access patterns?  
                This is where special access comes in: it enables us to leverage any access patterns, 
                while preventing collisions across storage spaces.
                <br/><br/>
            </P2>

            <img style = {{marginTop: "20px"}} className = {pageStyles.Img} src = {specialAccess} />

        </PageLayout>
        
    )
}
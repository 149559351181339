import styles from './TopMenu.module.css';
import '../../../css/TextStyles.css'
import '../../../css/keyFonts.css'
import xlogo from '../../../assets/xlogo2.png';
import tglogo from '../../../assets/tglogo2.png';
import gsap from 'gsap';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function TopMenu(props)
{
    const width = window.innerWidth;
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if(!loaded)
        {
            gsap.fromTo("#TopMenuContainer", {y: -30, opacity: 0}, {y: 0, opacity: 1, delay: 5, onComplete: () => {setLoaded(true)}})
        }
    }, [])
    
    if(width > 550)
    {
        return(
            <div  className={styles.Container} id = "TopMenuContainer">
    
                <a 
                href="https://twitter.com/honeybadgerweb3" 
                target="_blank" 
                rel="noopener noreferrer"
                >
                    <img src={xlogo} className={styles.Image} alt="Twitter Logo" />
                </a>
                
                <a 
                href="https://t.me/honeybadgersafeguard" 
                target="_blank" 
                rel="noopener noreferrer"
                >
                    <img src = {tglogo} className={styles.Image}/>
                </a>
                {/* <a 
                    style = {{textDecoration: "none"}}
                    href="https://docs.google.com/forms/d/e/1FAIpQLSegpfAL2oZ1N8vXd3-nwLpIacpMzklDaT_CtQ8EJxd6yp2B0Q/viewform?usp=header" 
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    <h2 
                        className={`${styles.Item} orbitron ShinyText`}> 
                        ⚡ AIRDROP - Session 1⚡
                    </h2>
                </a> */}

                <Link
                    style={{textDecoration: 'none'}}
                    to = {{
                        pathname: '/docs'
                    }}>
                    <h2 
                        style={{textDecoration: 'none'}}
                        className={`${styles.Item} orbitron`}
                        > How it Works </h2>
                </Link>

                 <Link 
                 style={{textDecoration: 'none'}}
                    to = {{
                        pathname: '/docs',
                        search: '?section=1.4',
                        state: {section: 1.4}
                    }}>
                <h2 
                    className={`${styles.Item} orbitron`}
                    > Roadmap </h2>
                </Link>

                <Link 
                style={{textDecoration: 'none'}}
                    to = {{
                        pathname: '/docs',
                        search: '?section=1.2',
                        state: {section: 1.2}
                    }}>
                    <h2 
                        className={`${styles.Item} orbitron`}
                        > Tokenomics </h2>
                </Link>
                <a 
                href="https://ethereum-magicians.org/t/erc-7844-consolidated-dynamic-storage-cds/22217" 
                target="_blank" 
                rel="noopener noreferrer"
                style = {{textDecoration: "none"}}
                >
                     <h2 className={`${styles.Item} orbitron`}> <span className = {"ShinyText"}>⧫</span> ERC-7844 </h2>
                </a>

                <Link 
                 style={{textDecoration: 'none'}}
                    to = {{
                        pathname: '/contribute',
                    }}>
                <h2 
                    className={`${styles.Item}  orbitron`}
                    > Contributor Program </h2>
                </Link>

                
            </div>
        )
    }
    else
    {
        return(
            <div className={styles.Container2} id = "TopMenuContainer">
                <div className={styles.ItemsContainer}>
                <a 
                href="https://twitter.com/honeybadgerweb3" 
                target="_blank" 
                rel="noopener noreferrer"
                >
                    <img src={xlogo} className={styles.Image} alt="Twitter Logo" />
                </a>
                    <img src = {tglogo} className={styles.Image}/>
                    <h2 onClick = {() => {props.setTopMenuSection(1)}} className={`${styles.Item} opensans`}> [How it Works] </h2>
                    <h2 onClick = {() => {props.setTopMenuSection(2)}} className={`${styles.Item}  opensans`}> [FAQ] </h2>
                    <h2 onClick = {() => {false && props.setTopMenuSection(3)}} className={`${styles.Item}  opensans`}> [🚧 Roadmap] </h2>
                </div>
                
                <div className={styles.ItemsContainer}>
                    <h2 onClick = {() => {false && props.setTopMenuSection(4)}} className={`${styles.Item} opensans`}> [🚧 Tokenomics] </h2>
                    <h2 onClick = {() => {false && props.setTopMenuSection(5)}} className={`${styles.Item} opensans`}> [🚧 Stake HoneyBadger Token] </h2>
                </div>


            </div>
        )
    }

}
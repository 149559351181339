import { supabase } from "../../../utils/supabaseclient";

export const handleClaim = async ({
    taskId, 
    userId, 
    currentTask,
    setCurrentTask, 
    setUpdate,
    update,
    setUpdateTask
}) => {
    if (!userId) {
        alert('You need to be logged in to claim a task.');
        return;
    }

    if (currentTask) {
        alert(`You already have a task. Complete or cancel it before claiming a new one.`);
        return;
    }

    const { data, error } = await supabase.rpc('claim_task_transaction', {
        p_contributor_id: userId,
        p_task_id: taskId
    });

    if (error || data !== 'Success') {
        console.error('Error claiming task:', error, data);
        alert('Failed to claim task. Try again.');
        return;
    }

    alert('Task claimed successfully!');
    setCurrentTask({ id: taskId });
    setUpdate(update + 1);
    setUpdateTask(true);
};
import { useEffect, useState } from "react";
import '../css/BulletinBoard.css'
import { fetchedPosts } from "./BulletinBoardPosts";

export default function BulletinBoard() {

    return (
        <div className="bulletin-board">
            <h3 className="bulletin-title">News and Upcoming Changes</h3>
            <div className="bulletin-container">
                {fetchedPosts.length > 0 ? (
                    fetchedPosts.map((post) => (
                        <div key={post.id} className="bulletin-post">
                            <h4>{post.title}</h4>
                            <p>{post.content}</p>
                            <span className="bulletin-date">{post.date}</span>
                        </div>
                    ))
                ) : (
                    <p className="no-updates">No recent updates.</p>
                )}
            </div>
        </div>
    );
}

import { supabase } from "./supabaseclient";
import cp from '../components/ContributorPage/contributorpage.module.css';
import gh from '../assets/githubicon.png';
import CoreTenets from "./CoreTenets";
import tglogo from '../assets/tglogo2.png';
import styles from './CoreTenets.module.css';

export const LoginButton = () => 
{
    const handleLogin = async () => {
        const redirectUrl =
            window.location.hostname === "localhost"
                ? "http://localhost:3000/contribute"
                : "https://www.honeybadgerframework.com/contribute";
    
        const { error } = await supabase.auth.signInWithOAuth({
            provider: "github",
            options: { redirectTo: redirectUrl }
        });
    
        if (error) {
            console.error("Login failed!", error.message);
        }
    };

    return (
        <>
            <div className={styles.R2}>
                <button onClick={() => { window.open("https://t.me/+b8ZbMRFX1xEyYzE5", "_blank", "noopener,noreferrer"); }}  className = {cp.LoginButton}>
                    <img src = {tglogo} style = {{width: "35px", height: "35px"}} />
                    Join the Community
                </button>

                <button className = {cp.LoginButton} onClick = {handleLogin}>
                    <img src = {gh} style = {{width: "35px", height: "35px"}} />
                    Sign Up With Github OAuth
                </button>
            </div>

            <h2 className = {styles.Header}>
            Why We're Building: HoneyBadger's Core Tenets
            </h2>
            <CoreTenets />
        </>
    )
}


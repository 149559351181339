export const fetchedPosts = [
    { 
        id: 1, 
        title: "Contributor Program Beta!", 
        content: "The Contributor program has officially moved from Alpha -> Beta!", 
        date: "March 18, 2025" 
    },
    { 
        id: 2, 
        title: "Upcoming Feature: Skills", 
        content: "Level up skills (ie; auditing, collaboration, projects) for added bonuses!", 
        date: "March 18, 2025" 
    },
    { 
        id: 3, 
        title: "Upcoming Feature: Leaderboard", 
        content: "See how you stack up against other contributors and battle for the top spot!", 
        date: "March 18, 2025" 
    },
    { 
        id: 4, 
        title: "Upcoming Page: Project Highlights", 
        content: "Project highlights will feature every approved project, and allow project contributors to configure the page to their liking!", 
        date: "March 18, 2025" 
    },
    { 
        id: 5, 
        title: "Upcoming Section: Contributors", 
        content: "All contributors will be featured on the home page, linking directly to their own profile pages!", 
        date: "March 18, 2025" 
    },
    { 
        id: 6, 
        title: "Upcoming Feature: Profile Pages", 
        content: "Users will be able to create their own profile pages!", 
        date: "March 18, 2025" 
    }
];


import '../../../../css/keyFonts.css';
import styles from '../Common/page.module.css';
import PageLayout from '../Common/PageLayout';
import {P, H, Tab, Ul, Li, Sm, BigLi, H2, S, White, P2} from '../Common/CommonElements'

import funs from '../../../../assets/funs.svg';


export default function Page_7_2({mobileMode})
{
    return (
        <PageLayout mobileMode = {mobileMode}>
            <h2 className={styles.PageHeader}>
                HoneyBadger Architecture
            </h2>

            <P>
                HoneyBadger's logic is contained within the HoneyBadger contract.  
                Storage operations are delegated to the HoneyBadger layer from linked contracts,
                allowing disparate layers to share an equivalent storage picture.

                <br/><br/>

                Each dependent contract has its own permissions layout.  Permissions can be 
                fine-grained (ie; storage space-specific, entry-specific) and role-based, 
                or purely-role based.
            </P>
            <S/>
            <S/>

            <div className={styles.Center} >
                <img className = {styles.Img2} src = {funs} />
            </div>
            <p style = {{textAlign: 'center', color: 'white'}}>

                Core functions (base model)

            </p>
            
            <S />
            <S />
            <S />

            <H2>How Data Works</H2>
            <P>
                HoneyBadger has a consistent approach to defining data: create your data structure, 
                then put it in a storage space.  If you need many instances, just call <i>push(amount, storageSpace) </i> 
                to allocate new <i>entries.</i>

                <br/><br/>

                HoneyBadger refers to storage space items as <i>members </i> and instances within a storage space 
                as <i>entries. </i>

                <br/><br/>
                Hence, the <i>get </i> function looks like: <br/><br/>
                <code>get(memberIndex, entryIndex, storageSpace, specialAccessCode)</code>
                <br/><br/>
                We might use it like: 
                <br/><br/>
                <code>uint256 user_height = get(uint256(User.height), userId, USER_DATA_STORAGE_SPACE, "0x")</code>

                <br/><br/>
                We cover special access patterns in the "Using HoneyBadger" section - <i>Hint: what if we need nested access?  What about 
                address-indexing?</i>
            </P>


        </PageLayout>
    )
}
import { useEffect, useState } from 'react';
import { supabase } from '../../../../utils/supabaseclient';
import '../css/TaskReviewPanel.css';

export default function TaskReviewPanel() {
    const [submissions, setSubmissions] = useState([]);

    useEffect(() => {
        fetchSubmissions();
    }, []);

    const fetchSubmissions = async () => {
        const { data, error } = await supabase
            .from('task_submissions')
            .select(`
                id, 
                task_id, 
                submission_link, 
                contributor_id, 
                status, 
                tasks!inner(title, points), 
                contributors!task_submissions_contributor_id_fkey(telegram)
            `)
            .eq('status', 'pending')
            .order('submitted_at', { ascending: false });

        if (!error) {
            setSubmissions(data);
        } else {
            console.error("Fetch Submissions Error:", error);
        }
    };
    const approveSubmission = async (submissionId, maxPoints) => {
        const points = window.prompt(`Enter points to award (Max: ${maxPoints}):`);
    
        if (!points || isNaN(points) || points <= 0 || points > maxPoints) {
            alert(`Invalid points. Must be between 1 and ${maxPoints}.`);
            return;
        }
    
        const { data, error: fetchError } = await supabase
            .from('task_submissions')
            .select('task_instance_id')
            .eq('id', submissionId)
            .single();
    
        if (fetchError || !data) {
            alert('Error fetching task instance ID.');
            console.error(fetchError);
            return;
        }
    
        const taskInstanceId = data.task_instance_id;
    
        const { error: rpcError } = await supabase.rpc('approve_submission', {
            p_submission_id: submissionId,
            p_task_instance_id: taskInstanceId,
            p_points_awarded: parseInt(points, 10)
        });
    
        if (rpcError) {
            alert('Error approving submission.');
            console.error(rpcError);
            return;
        }
    
        alert(`Submission approved! Awarded ${points} points.`);
        fetchSubmissions();
    };

    const rejectSubmission = async (submissionId) => {

        const { data, error: fetchError } = await supabase
            .from('task_submissions')
            .select('task_instance_id')
            .eq('id', submissionId)
            .single();

        if (fetchError || !data) {
            alert('Error fetching task instance ID.');
            console.error(fetchError);
            return;
        }

        const taskInstanceId = data.task_instance_id;

        const { error: rpcError } = await supabase.rpc('reject_submission', {
            p_submission_id: submissionId,
            p_task_instance_id: taskInstanceId 
        });

        if (rpcError) {
            alert('Error rejecting submission.');
            console.error(rpcError);
            return;
        }

        alert('Submission rejected.');
        fetchSubmissions();
    };

    return (
        <div className='task-review-container'>
            <h3>Task Submissions for Review</h3>
            {submissions.length === 0 ? (
                <p className="no-submissions">No pending submissions.</p>
            ) : (
                <div className="submissions-scroll-container">
                    {submissions.map((submission) => (
                        <div key={submission.id} className="submission-card">
                            <h3 className="submission-title">{submission.tasks.title}</h3>
                            <p className="submission-meta"><strong>Contributor:</strong> {submission.contributors.telegram || "Unknown"}</p>
                            <p className="submission-meta"><strong>Max Points:</strong> {submission.tasks.points}</p>
                            <p className="submission-meta">
                                <strong>Submission:</strong> 
                                <a 
                                    href={submission.submission_link.startsWith('http') ? submission.submission_link : `https://${submission.submission_link}`} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    View
                                </a>
                            </p>
                            <div className="submission-actions">
                                <button onClick={() => approveSubmission(submission.id, submission.tasks.points)}>Approve</button>
                                <button className="reject-btn" onClick={() => rejectSubmission(submission.id)}>Reject</button>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
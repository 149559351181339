import { supabase } from "../../../utils/supabaseclient";

export const sendInvite = async ({inviteUsername, setInviteUsername, setUpdate, update, userId, currentTask}) => {
    if (!inviteUsername) {
        alert('Enter a valid Telegram username.');
        return;
    }

    const { data, error } = await supabase.rpc('invite_user_to_task', {
        p_invitee_username: inviteUsername, 
        p_inviter_id: userId,
        p_task_id: currentTask.id,
        p_task_instance_id: currentTask.instanceId
    });

    if (error) {
        alert('Failed to send invite.');
        console.error('Invite error:', error);
        return;
    }

    alert('Invite sent successfully!');
    setInviteUsername('');
    setUpdate(update + 1);
};
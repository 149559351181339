/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { forwardRef, useEffect, useRef, useState, useMemo } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import {  Trail, useGLTF } from "@react-three/drei";
//import { RGBELoader } from "three-stdlib";
import { MeshPhongMaterial, MeshBasicMaterial, Vector3, Vector2 } from "three";
import easeInOutQuad from "../Helpers/easeInOutQuad";
import { lerp } from "three/src/math/MathUtils";
//import { useLoader } from "@react-three/fiber";
//import sky from '../../assets/sky.hdr'
import { fragmentShader, vertexShader } from "./shadershb";
import { ShaderMaterial } from "three";
import * as THREE from 'three'
import gsap from "gsap";
import { deviceType } from "react-device-detect";

const HoneyBadgerModel = forwardRef((props, ref) => {
    const { nodes } = useGLTF("/hb.glb");
    const [cameraPos, setPos] = useState(new Vector3())
    const hasLoaded = useRef(false)

    const moveUpTime = useRef(0)


    let ev;
    const rot = useRef(0)

    const wait = useRef(0)

    const r = useRef()
    const r2 = useRef()
    const r3 = useRef()

    const backMaterial = new MeshPhongMaterial({color: "white", shininess: 50, reflectivity: 50, transparent: true, opacity: (hasLoaded.current) ? 1 : 0});
    const eyesMaterial = new MeshBasicMaterial({color: "black", transparent: true, opacity: (hasLoaded.current) ? 1 : 0});
    const bodyMaterial = new MeshPhongMaterial({color: "grey", shininess: 50, reflectivity: 50, transparent: true, opacity: (hasLoaded.current) ? 1 : 0});

    const HoverOut = useRef(null)
    const [hovered, setHovered] = useState(false)

    const elapsed = useRef(0)

    const shaderMat = useMemo(() =>
    {
       return new ShaderMaterial({
            extensions: 
            {
                derivatives: "#extension GL_OES_standard_derivatives : enable"
            },
            uniforms: {
                time: {value: 0},
                u_hovered: {value: 0.}
            },
            fragmentShader: fragmentShader,
            vertexShader: vertexShader,
            wireframe: false,
        });
    }, [])


    const {camera} =  useThree();


    if(ref.current != undefined)
    {
        HoverOut.current = gsap.fromTo(camera.position, {z: cameraPos.z}, {z: camera.position.z - .15, paused: true, duration: .2})
    }

    useEffect(() =>
    {
        document.body.style.cursor = (hovered && props.phase == 0) ? 'pointer':'auto';

        if(cameraPos !== undefined)
        {
            cameraPos.x = camera.position.x;
            cameraPos.y = camera.position.y;
            cameraPos.z = camera.position.z;
        }


    }, [hovered])

    useFrame((_, delta) =>
    {
        if(hasLoaded.current === false && r.current)
        {
            if(wait.current < 1)
            {
                wait.current += delta;
                return;
            }
            else if(r.current.material.opacity < 1)
            {
                r.current.material.opacity += delta * .35;
                r2.current.material.opacity += delta * .35;
                r3.current.material.opacity += delta * .35;
            }
            else
            {
                hasLoaded.current = true;
            }

        }


        if(r.current != undefined && hovered && props.hasTransitioned === false && hasLoaded.current == true)
        {
            r.current.material.uniforms.time.value = r.current.material.uniforms.time.value + delta;
        }


        if(props.phase == 1 && ref.current.scale.x > 0 && props.hasTransitioned == false)
        {

            if(rot.current < 100)
            {
                rot.current += delta * 25;
                ev = easeInOutQuad(rot.current / 100)
                ref.current.rotation.y = lerp(Math.PI / 2, Math.PI, ev);
            }
            if(rot.current >= 100)
            {
                let deltaValue = (deviceType === "browser") ? delta * 30.5 : delta * 2.5;
                ref.current.scale.x -= deltaValue;
                ref.current.scale.y -= deltaValue;
                ref.current.scale.z -= deltaValue;    
            }
            if(document.body.style.cursor != 'auto') {document.body.style.cursor = 'auto'}
        }
        
        else if(props.hasTransitioned == false && props.phase == 1)
        {
            props.setTransitioned(true)
        }
        elapsed.current += delta;

        if(props.hasTransitioned == true)
        {
            ref.current.scale.x = 0;
            ref.current.scale.y = 0;
            ref.current.scale.z = 0;
        }
    })

    return (
        <group ref={ref} {...props} dispose={null}   position = {[0, (hasLoaded.current) ? -4 : -15, -10]} >
            <group 
            onPointerOver={(e) => 
            {
                if(props.phase == 0)
                {
                    if(!r.current) return;
                    if(r.current.material.opacity < .2) return;
                    e.stopPropagation();
                    setHovered(true)
                    props.setHovered(true)

                    if(HoverOut.current)
                    {
                        HoverOut.current.restart();
                    }

                }

            }}
            onPointerOut={(e) =>
            {
                if(props.phase == 0)
                {
                    if(!r.current) return;
                    if(r.current.material.opacity < .2) return;

                    e.stopPropagation();
                    setHovered(false);
                    props.setHovered(false)

                    if(HoverOut.current)
                    {
                        HoverOut.current.reverse();
                    }

                }

            }}
            onClick={() => {
                props.setPhase(1)
                props.setSwapTime(elapsed.current)}
            }
            >
                <mesh
                    ref = {r}
                    castShadow
                    receiveShadow
                    geometry={nodes.HoneyBadger_1.geometry}
                    material={((hovered) ? shaderMat : bodyMaterial)}>
                </mesh>

                <mesh
                    ref = {r2}
                    castShadow
                    receiveShadow
                    geometry={nodes.HoneyBadger_2.geometry}
                    material={(hovered) ? shaderMat : backMaterial}
                />
                <mesh
                    ref = {r3}
                    castShadow
                    receiveShadow
                    geometry={nodes.HoneyBadger_3.geometry}
                    material={(hovered) ? shaderMat : backMaterial}
                />
            </group>
        </group>
    );
});

const HoneyBadgerFlying = forwardRef((props, ref) => {
    const { nodes } = useGLTF("/hb.glb");
    let ev;
    const rot = useRef(0)

    const r = useRef()

    const backMaterial = new MeshPhongMaterial({color: "white", shininess: 50, reflectivity: 50});
    const eyesMaterial = new MeshBasicMaterial({color: "black"});
    const bodyMaterial = new MeshPhongMaterial({color: "grey", shininess: 50, reflectivity: 50});

    const [hovered, setHovered] = useState(false)

    const elapsed = useRef(0)

    const shaderMat = useMemo(() =>
    {
       return new ShaderMaterial({
            extensions: 
            {
                derivatives: "#extension GL_OES_standard_derivatives : enable"
            },
            uniforms: {
                time: {value: 0},
            },
            fragmentShader: fragmentShader,
            vertexShader: vertexShader,
            wireframe: false,
        });
    }, [])

    useEffect(() =>
    {
        document.body.style.cursor = (hovered && props.phase == 0) ? 'pointer':'auto';
    }, [hovered])

    useFrame((_, delta) =>
    {
        if(r.current != undefined && hovered && props.hasTransitioned === false)
        {
            r.current.material.uniforms.time.value = r.current.material.uniforms.time.value + delta;
        }


        if(props.phase == 1 && ref.current.scale.x > 0 && props.hasTransitioned == false)
        {
            ref.current.scale.x -= delta * 8;
            ref.current.scale.y -= delta * 8;
            ref.current.scale.z -= delta * 8;

            if(rot.current < 100)
            {
                rot.current += delta * 50;
                ev = easeInOutQuad(rot.current / 100)
                ref.current.rotation.y = lerp(Math.PI / 2, Math.PI, ev)
            }
            if(document.body.style.cursor != 'auto') {document.body.style.cursor = 'auto'}
        }
        else if(props.hasTransitioned == false && props.phase == 1)
        {
            props.setTransitioned(true)
        }
        elapsed.current += delta;

        if(props.hasTransitioned == true)
        {
            ref.current.scale.x = 0;
            ref.current.scale.y = 0;
            ref.current.scale.z = 0;
        }
    })

    return (
        <group ref={ref} {...props} dispose={null}>
            <group position={[0, -1, 0]} 
            onPointerOver={(e) => 
            {
                if(props.phase == 0)
                {
                    e.stopPropagation();
                    setHovered(true)
                    props.setHovered(true)
                }

            }}
            onPointerOut={(e) =>
            {
                if(props.phase == 0)
                {
                    e.stopPropagation();
                    setHovered(false);
                    props.setHovered(false)
                }

            }}
            onClick={() => {
                props.setPhase(1)
                props.setSwapTime(elapsed.current)}
            }
            >
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.HoneyBadger_1.geometry}
                    material={(hovered) ? shaderMat : bodyMaterial}>
                </mesh>

                <mesh
                    ref = {r}
                    castShadow
                    receiveShadow
                    geometry={nodes.HoneyBadger_2.geometry}
                    material={(hovered) ? shaderMat : backMaterial}
                />
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.HoneyBadger_3.geometry}
                    material={eyesMaterial}
                />
            </group>
        </group>
    );
});


export const HoneyBadgerModelShiny = forwardRef((props, ref) => {
    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()

    const { nodes } = useGLTF("/hb.glb");
    const timerRef = useRef(0);
    const rot = useRef(0);
    const backMaterial = new MeshBasicMaterial({color: "white", shininess: 50, reflectivity: 50, transparent: true, opacity: (props.hasPageChanged) ? 1 : 0});
    const eyesMaterial = new MeshBasicMaterial({color: "black", transparent: true, opacity:  (props.hasPageChanged) ? 1 : 0});
    const bodyMaterial = new MeshPhongMaterial({color: "grey", shininess: 50, reflectivity: 50, transparent: true, opacity:  (props.hasPageChanged) ? 1 : 0});
 
    const hovered = useRef(false)

    let ev;

    useFrame((state, delta) =>
    {
        if(props.phase === 1)
        {
            if(ref1.current.material.opacity < .7 && state.clock.getElapsedTime() > props.swapTime + 4)
            {
                ref1.current.material.opacity += delta * .1;
                ref2.current.material.opacity += delta * .1;
                ref3.current.material.opacity += delta * .1;
            }

            if(ref2.current != undefined && hovered.current == true)
            {
                if(props.left == true)
                {
                    ref2.current.material.color.g = 0
                    ref2.current.material.color.b = 0
                }
                else
                {
                    ref2.current.material.color.b = 0
                    ref2.current.material.color.r = 0
                }

            }
            else if(ref2.current != undefined && hovered.current == false && ref2.current.material.color.b != 1)
            {
                ref2.current.material.color.g = 1
                ref2.current.material.color.b = 1
                ref2.current.material.color.r = 1
            }
        }

    })



    return (
        <group ref={ref} {...props} dispose={null}>
            <group position={[0, 0, 0]} 
            onPointerOver={() => {
                if(props.phase > 0)
                {
                    document.body.style.cursor = 'pointer'
                    hovered.current = true;
                }

            }}
            onPointerOut={() =>
            {
                document.body.style.cursor = 'auto'
                hovered.current = false;
            }}
            onClick={() =>
            {
                if(props.phase > 0)
                {
                    if(!props.hasPageChanged)
                    {
                        props.setPageChanged(true);
                    }

                    if(props.left == true)
                    {
                        if(props.page != 0)
                        {
                            props.setPage(props.page - 1);
                        }
                    }
                    else
                    {
                        if(props.page != props.pageMax)
                        {
                            props.setPage(props.page + 1);
                        }
                    }
                }

            }}
            >
                <mesh
                    ref = {ref1}
                    castShadow
                    receiveShadow
                    geometry={nodes.HoneyBadger_1.geometry}
                    material={bodyMaterial}>
                </mesh>

                <mesh
                    ref = {ref2}
                    castShadow
                    receiveShadow
                    geometry={nodes.HoneyBadger_2.geometry}
                    material={backMaterial}
                />
                <mesh
                    ref = {ref3}
                    castShadow
                    receiveShadow
                    geometry={nodes.HoneyBadger_3.geometry}
                    material={eyesMaterial}
                />
            </group>
        </group>
    );
});

export const HoneyBadgerModelShinyDisplay = (props) =>
{
    const ref = useRef()
    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()



    const { nodes } = useGLTF("/hb.glb");

    const backMaterial = new MeshPhongMaterial({color: "white", shininess: 50, reflectivity: 50});
    const eyesMaterial = new MeshPhongMaterial({color: "black", transparent: true});
    const bodyMaterial = new MeshPhongMaterial({color: "grey", shininess: 50, reflectivity: 50});
 
    const hovered = useRef(false)

    let ev;

    useFrame((state, delta) =>
    {
        ref.current.rotation.y += delta * .25;

    })

    return (
        <group ref={ref} {...props} dispose={null}>
            <group position={[0, 0, 0]} 
            >
                <mesh
                    ref = {ref1}
                    castShadow
                    receiveShadow
                    geometry={nodes.HoneyBadger_1.geometry}
                    material={bodyMaterial}>
                </mesh>

                <mesh
                    ref = {ref2}
                    castShadow
                    receiveShadow
                    geometry={nodes.HoneyBadger_2.geometry}
                    material={backMaterial}
                />
                <mesh
                    ref = {ref3}
                    castShadow
                    receiveShadow
                    geometry={nodes.HoneyBadger_3.geometry}
                    material={eyesMaterial}
                />
            </group>
        </group>
    );
};

export const HoneyBadgerModelBarebones = forwardRef((props, ref) => {
    const {camera} = useThree()

    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()
    const re = useRef()

    const dirX = useRef(true)
    const dirY = useRef(true)

    const { nodes } = useGLTF("/hb.glb");

    const backMaterial = new MeshPhongMaterial({color: "white", shininess: 50, reflectivity: 50});
    const eyesMaterial = new MeshBasicMaterial({color: "black"});
    const bodyMaterial = new MeshPhongMaterial({color: "grey", shininess: 50, reflectivity: 50,});

    const shaderMat = useMemo(() =>
    {
       return new ShaderMaterial({
            extensions: 
            {
                derivatives: "#extension GL_OES_standard_derivatives : enable"
            },
            uniforms: {
                time: {value: 0},
            },
            fragmentShader: fragmentShader,
            vertexShader: vertexShader,
            wireframe: false,
        });
    }, [])

  
    useEffect(() => {
        const onMouseMove = (event) => {
          if (re.current) {
            // Calculate the mouse position in normalized device coordinates
            const mouse = new Vector2();
            mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
            mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
      
            // Convert the mouse position to world coordinates
            const raycaster = new THREE.Raycaster();
            raycaster.setFromCamera(mouse, camera);
      
            // Get the intersection point with a plane at z=0 (or your desired z-value)
            const intersection = new Vector3();
            raycaster.ray.intersectPlane(new THREE.Plane(new Vector3(0, 0, 1)), intersection);
      
            // Calculate the direction from the HoneyBadger to the intersection point
            const direction = new Vector3();
            direction.subVectors(intersection, re.current.position);
            direction.normalize();
      
            // Set the rotation of the HoneyBadger to look at the mouse
            re.current.quaternion.setFromUnitVectors(new Vector3(0, 0, 1), direction);
          }
        };
      
        // Add the mouse move event listener
        window.addEventListener('mousemove', onMouseMove);
      
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('mousemove', onMouseMove);
        };
      }, [camera]); // Ensure to include camera in the dependency array
  

    useFrame((state, delta) =>
    {
        //ref2.current.material.uniforms.time.value = ref2.current.material.uniforms.time.value + delta;
        //re.current.rotation.y += delta;

        if(dirX.current)
        {
            re.current.position.x += delta * Math.random() / 3;
            if(re.current.position.x > 1)
            {
                dirX.current = false
            }
        }
        else
        {
            re.current.position.x -= delta * Math.random() / 3
            if(re.current.position.x < -1)
            {
                dirX.current = true
            }
        }

        if(dirY.current)
        {
            re.current.position.y += delta * Math.random() / 2;
            if(re.current.position.y > .8)
            {
                dirY.current = false
            }
        }
        else
        {
            re.current.position.y -= delta * Math.random() / 2
            if(re.current.position.y < -.8)
            {
                dirY.current = true
            }
        }

        if(re.current.position.y < -2 ||  re.current.position.y > 2 || re.current.position.x > 2 || re.current.position.x < -2)
        {
            re.current.position.x = 0;
            re.current.position.y = 0;
            re.current.position.z = 0;
        }

    })

    return (
        <group ref={ref} {...props} dispose={null}>
            <group position={[0, 0, 0]} 
            ref = {re}
            >
                <mesh
                    ref = {ref1}
                    castShadow
                    receiveShadow
                    geometry={nodes.HoneyBadger_1.geometry}
                    material={backMaterial}>
                </mesh>


                <mesh
                    ref = {ref2}
                    castShadow
                    receiveShadow
                    geometry={nodes.HoneyBadger_2.geometry}
                    material={bodyMaterial}
                />
                <mesh
                    ref = {ref3}
                    castShadow
                    receiveShadow
                    geometry={nodes.HoneyBadger_3.geometry}
                    material={eyesMaterial}
                />
            </group>
        </group>
    );
});

export const HoneyBadgerModelBarebones2 = forwardRef((props, ref) => {
    const {camera} = useThree()

    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()
    const re = useRef()

    const dirX = useRef(true)
    const dirY = useRef(true)

    const { nodes, materials } = useGLTF('/spaceman.glb')

    const backMaterial = new MeshPhongMaterial({color: "white", shininess: 50, reflectivity: 50});
    const eyesMaterial = new MeshBasicMaterial({color: "black"});
    const bodyMaterial = new MeshPhongMaterial({color: "grey", shininess: 50, reflectivity: 50,});
    const helm = new MeshBasicMaterial({color: 'white'})
    const helmface = new MeshBasicMaterial({color: 'black'})

    const shaderMat = useMemo(() =>
    {
       return new ShaderMaterial({
            extensions: 
            {
                derivatives: "#extension GL_OES_standard_derivatives : enable"
            },
            uniforms: {
                time: {value: 0},
            },
            fragmentShader: fragmentShader,
            vertexShader: vertexShader,
            wireframe: false,
        });
    }, [])

  
    useEffect(() => {
        const onMouseMove = (event) => 
        {
          if (re.current) {
            // Calculate the mouse position in normalized device coordinates
            const mouse = new Vector2();
            mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
            mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
      
            // Convert the mouse position to world coordinates
            const raycaster = new THREE.Raycaster();
            raycaster.setFromCamera(mouse, camera);
      
            // Get the intersection point with a plane at z=0 (or your desired z-value)
            const intersection = new Vector3();
            raycaster.ray.intersectPlane(new THREE.Plane(new Vector3(0, 0, 1)), intersection);
      
            // Calculate the direction from the HoneyBadger to the intersection point
            const direction = new Vector3();
            direction.subVectors(intersection, re.current.position);
            direction.normalize();
      
            // Set the rotation of the HoneyBadger to look at the mouse
            re.current.quaternion.setFromUnitVectors(new Vector3(0, 0, 1), direction);
          }
        };
      
        // Add the mouse move event listener
        window.addEventListener('mousemove', onMouseMove);
      
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('mousemove', onMouseMove);
        };
      }, [camera]); // Ensure to include camera in the dependency array
  

    useFrame((state, delta) =>
    {
        //ref2.current.material.uniforms.time.value = ref2.current.material.uniforms.time.value + delta;
        //re.current.rotation.y += delta;

        if(dirX.current)
        {
            re.current.position.x += delta * Math.random() / 3;
            if(re.current.position.x > .5)
            {
                dirX.current = false
            }
        }
        else
        {
            re.current.position.x -= delta * Math.random() / 3
            if(re.current.position.x < -.5)
            {
                dirX.current = true
            }
        }

        if(dirY.current)
        {
            re.current.position.y += delta * Math.random() / 2;
            if(re.current.position.y > .2)
            {
                dirY.current = false
            }
        }
        else
        {
            re.current.position.y -= delta * Math.random() / 2
            if(re.current.position.y < -.2)
            {
                dirY.current = true
            }
        }

        if(re.current.position.y < -2 ||  re.current.position.y > 2 || re.current.position.x > 2 || re.current.position.x < -2)
        {
            re.current.position.x = 0;
            re.current.position.y = 0;
            re.current.position.z = 0;
        }

    })

    return (
        <group {...props} dispose={null}>
          <group position={[0, 0.246, 0]} ref = {re}>
            <mesh
                ref = {ref1}
              castShadow
              receiveShadow
              geometry={nodes.HoneyBadger_1.geometry}
              material={backMaterial}
            />
            <mesh
              castShadow
              ref = {ref2}
              receiveShadow
              geometry={nodes.HoneyBadger_2.geometry}
              material={bodyMaterial}
            />
            <mesh
            ref = {ref3}
              castShadow
              receiveShadow
              geometry={nodes.HoneyBadger_3.geometry}
              material={eyesMaterial}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Mesh_0.geometry}
                material={helm}
                position={[0.015, 0, 0.1]}
                rotation={[1.436, 0, 0]}
                scale={0.035}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Mesh_0001.geometry}
                material={helmface}
                position={[0.015, 0, 0.1]}
                rotation={[1.436, 0, 0]}
                scale={0.035}
            />
          </group>

        </group>
      )

});

export const HoneyBadgerSpaceman2 = forwardRef((props, ref) => {
    const {camera} = useThree()

    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()
    const re = useRef()

    const dirX = useRef(true)
    const dirY = useRef(true)

    const { nodes } = useGLTF('/spaceman.glb')

    const backMaterial = new MeshPhongMaterial({color: "white", shininess: 50, reflectivity: 50});
    const eyesMaterial = new MeshBasicMaterial({color: "black"});
    const bodyMaterial = new MeshPhongMaterial({color: "grey", shininess: 50, reflectivity: 50,});
    const helm = new MeshPhongMaterial({color: "white", shininess: 50, reflectivity: 50});
    const helmface =  new MeshPhongMaterial({color: "grey", shininess: 50, reflectivity: 50,});
  
    useEffect(() => {
        const onMouseMove = (event) => 
        {
          if (re.current) {
            // Calculate the mouse position in normalized device coordinates
            const mouse = new Vector2();
            mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
            mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
      
            // Convert the mouse position to world coordinates
            const raycaster = new THREE.Raycaster();
            raycaster.setFromCamera(mouse, camera);
      
            // Get the intersection point with a plane at z=0 (or your desired z-value)
            const intersection = new Vector3();
            raycaster.ray.intersectPlane(new THREE.Plane(new Vector3(0, 0, 1)), intersection);
      
            // Calculate the direction from the HoneyBadger to the intersection point
            const direction = new Vector3();
            direction.subVectors(intersection, re.current.position);
            direction.normalize();
      
            // Set the rotation of the HoneyBadger to look at the mouse
            re.current.quaternion.setFromUnitVectors(new Vector3(0, 0, 1), direction);
          }
        };
      
        // Add the mouse move event listener
        window.addEventListener('mousemove', onMouseMove);
      
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('mousemove', onMouseMove);
        };
      }, [camera]); // Ensure to include camera in the dependency array
  

    useFrame((_, delta) =>
    {
        //ref2.current.material.uniforms.time.value = ref2.current.material.uniforms.time.value + delta;
        //re.current.rotation.y += delta;
        if(dirY.current)
        {
            re.current.position.y += delta * .1;
            if(re.current.position.y > 1.2)
            {
                dirY.current = false
            }
        }
        else
        {
            re.current.position.y -= delta * .1;
            if(re.current.position.y < -1.2)
            {
                dirY.current = true
            }
        }

        if(dirX.current)
        {
            re.current.position.x += delta * .1;
            if(re.current.position.x > 1.2)
            {
                dirX.current = false
            }
        }
        else
        {
            re.current.position.x -= delta * .1;
            if(re.current.position.x < -1.2)
            {
                dirX.current = true
            }
        }

        if(re.current.position.y < -2 ||  re.current.position.y > 2 || re.current.position.x > 2 || re.current.position.x < -2)
        {
            re.current.position.x = 0;
            re.current.position.y = 0;
            re.current.position.z = 0;
        }

    })

    return (
        <group {...props} dispose={null}>
          <group position={[0, 0.246, 0]} ref = {re}>
            <mesh
                ref = {ref1}
              castShadow
              receiveShadow
              geometry={nodes.HoneyBadger_1.geometry}
              material={backMaterial}
            />
            <mesh
              castShadow
              ref = {ref2}
              receiveShadow
              geometry={nodes.HoneyBadger_2.geometry}
              material={bodyMaterial}
            />
            <mesh
            ref = {ref3}
              castShadow
              receiveShadow
              geometry={nodes.HoneyBadger_3.geometry}
              material={eyesMaterial}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Mesh_0.geometry}
                material={helm}
                position={[0.015, 0, 0.1]}
                rotation={[1.436, 0, 0]}
                scale={0.035}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Mesh_0001.geometry}
                material={helmface}
                position={[0.015, 0, 0.1]}
                rotation={[1.436, 0, 0]}
                scale={0.035}
            />
          </group>

        </group>
      )

});



  
useGLTF.preload('/spaceman.glb')
useGLTF.preload("/hb.glb");

export default HoneyBadgerModel;
import { supabase } from "../../../utils/supabaseclient";

export const submitTask = async ({e, submissionLink, userId, currentTask, setSubmissionLink, setCurrentTask, setUpdate, update}) => {
    e.preventDefault();
    if (!submissionLink) {
        alert("Please enter a submission link.");
        return;
    }

    const { data, error } = await supabase.rpc('submit_task', {
        p_contributor_id: userId,
        p_task_id: currentTask.id,
        p_submission_link: submissionLink
    });

    if (error) {
        alert("Error submitting task.");
        console.error("Submit task error:", error);
        return;
    }

    alert(data); 
    setSubmissionLink('');
    setCurrentTask(null);
    setUpdate(update + 1);
};
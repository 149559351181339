import { useEffect, useState } from "react";
import { fetchPendingWriteups } from "../../DBActions/Admin/fetchPendingWriteups";
import { approveWriteup } from "../../DBActions/Admin/approveWriteup";
import { rejectWriteup } from "../../DBActions/Admin/rejectWriteup";
import '../css/WriteupReview.css';

export default function WriteupReview({adminId}) {
    const [writeups, setWriteups] = useState([]);

    useEffect(() => {
        fetchPendingWriteups({ setWriteups });
    }, []);

    return (
        <div className="writeup-review-container">
            <h3>Pending Online Writeups</h3>
            <div className="writeup-scroll">
                {writeups.length === 0 ? (
                    <p style = {{textAlign: 'center', width: '100%'}}>No pending writeups.</p>
                ) : (
                    writeups.map(({ contributor_id, task_id, online_submission_link, contributors, tasks }) => (
                        <div key={`${contributor_id}-${task_id}`} className="writeup-card">
                            <p><strong>Telegram:</strong> {contributors.telegram || "Unknown"}</p>
                            <p><strong>Task:</strong> {tasks.title}</p>
                            <a href={online_submission_link.startsWith("http") ? online_submission_link : `https://${online_submission_link}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="view-button"
                            title={online_submission_link}>
                                View Writeup
                            </a>
                            <div className="actions">
                            <button onClick={() => {
                                const multiplier = parseFloat(prompt("Enter multiplier (1.0 - 1.5):"));
                                if (!isNaN(multiplier)) {
                                    approveWriteup({
                                        adminId: adminId,  // Make sure this is available in your component
                                        contributorId: contributor_id,
                                        taskId: task_id,
                                        multiplier,
                                        fetchWriteups: () => fetchPendingWriteups({ setWriteups })
                                    });
                                }
                            }}>Approve</button>
                                <button onClick={() => rejectWriteup({ contributorId: contributor_id, taskId: task_id, fetchWriteups: () => fetchPendingWriteups({ setWriteups }) })}>
                                    Reject
                                </button>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}
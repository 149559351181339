import { supabase } from "../../../../utils/supabaseclient";

export const rejectWriteup = async ({ contributorId, taskId, fetchWriteups }) => {
    const { error } = await supabase
        .from("claimed_tasks")
        .update({
            online_submission_link: null,
            online_submission_approved: false,
        })
        .eq("contributor_id", contributorId)
        .eq("task_id", taskId);

    if (error) {
        alert("Error rejecting writeup.");
        console.error(error);
    } else {
        alert("Writeup rejected.");
        fetchWriteups();
    }
};
import { supabase } from "../../../utils/supabaseclient";

export const acceptInvite = async ({ inviteId, userId, update, setUpdate }) => {
    try {
        // Step 1: Retrieve the inviter's ID using the invite ID
        const { data: inviteData, error: inviteError } = await supabase
            .from("task_invitations")
            .select("inviter_id, task_id")
            .eq("id", inviteId)
            .single();

        if (inviteError || !inviteData) {
            alert("Error fetching invite details.");
            console.error("Invite fetch error:", inviteError);
            return;
        }

        const inviterId = inviteData.inviter_id;
        const taskId = inviteData.task_id;

        // Step 2: Retrieve the task instance ID from the inviter's claimed tasks
        const { data: taskInstanceData, error: taskInstanceError } = await supabase
            .from("claimed_tasks")
            .select("task_instance_id")
            .eq("contributor_id", inviterId)
            .eq("task_id", taskId)
            .eq("completed", false)
            .single();

        if (taskInstanceError || !taskInstanceData) {
            alert("Error fetching task instance ID.");
            console.error("Task instance fetch error:", taskInstanceError);
            return;
        }

        const taskInstanceId = taskInstanceData.task_instance_id;

        // Step 3: Call the `accept_task_invite` function with the derived task instance ID
        const { data, error: acceptError } = await supabase.rpc("accept_task_invite", {
            p_invitee_id: userId,
            p_invite_id: inviteId,
            p_task_instance_id: taskInstanceId
        });

        if (acceptError) {
            alert(data);
            console.error("Accept invite error:", acceptError);
            return;
        }

        alert(data)

        setUpdate(update + 1);
    } catch (err) {
        console.error("Unexpected error in acceptInvite:", err);
        alert("An unexpected error occurred.");
    }
};

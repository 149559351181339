import '../../../../css/keyFonts.css'
import { CodeExamples, HB, KeyAdvantages, NonTechnical, PerformanceBenchmarks, PracticalUseCases, QuickGuide, VideoGuides } from '../Sections/NonTechnical'
import ItemSegment, { HiddenSegment } from './ItemSegment'
import styles from './SidebarItems2.module.css'
import Exit from '../../../../assets/Exit.svg'
import { Link } from 'react-router-dom'
import tg from '../../../../assets/tglogo.png';
import xlogo from '../../../../assets/xlogo2.png'

export default function SidebarItems2({page, setPage})
{
    const NonTechnicalItems = NonTechnical({page, setPage});
    const VideoGuideItems = VideoGuides({page, setPage});
    const Benchmarks = PerformanceBenchmarks({page, setPage});
    const KeyAdvantagesItems = KeyAdvantages({page, setPage});
    const PracticalUseCasesItems = PracticalUseCases({page, setPage});
    const QG = QuickGuide({page, setPage});
    const CE = CodeExamples({page, setPage})

    return(
        <div className={styles.SidebarItemsContainer}>
            <ItemSegment title = {`Quick Guide`} items = {QG} open = {true} page = {page}/>
            <ItemSegment title = "Project Overview" items={NonTechnicalItems} open = {true} page = {page}/>
            <ItemSegment title = {`Using HoneyBadger`} items = {CE} open = {true} page = {page}/>
            <ItemSegment title = {`Contributor Program`} open = {true} page = {page} />
            <ItemSegment title = {`🚧 Functions`} open = {true} page = {page}/>
            <ItemSegment title = {`🚧 Advanced FAQ`} open = {true} page = {page}/>
            <ItemSegment title = "Performance Benchmarks" open = {true} items = {Benchmarks} page = {page}/>
            <ItemSegment title = "Video Guides" open = {true} page = {page} items = {VideoGuideItems}/>
        </div>
    )
}

export function SidebarItemsMobile({
    page, 
    setPage, 
    mobileSidebarOpen, 
    setMobileSidebarOpen
})
{
    const QG = QuickGuide({page, setPage});
    const NonTechnicalItems = NonTechnical({page, setPage, mobileSidebarOpen, setMobileSidebarOpen});
    const VideoGuideItems = VideoGuides({page, setPage, mobileSidebarOpen, setMobileSidebarOpen});
    const Benchmarks = PerformanceBenchmarks({page, setPage, mobileSidebarOpen, setMobileSidebarOpen});
    const CE = CodeExamples({page, setPage, mobileSidebarOpen, setMobileSidebarOpen});
    const _HB = HB({page, setPage, mobileSidebarOpen, setMobileSidebarOpen})

    return(
        <div className={styles.SidebarItemsContainerMobile}>
            <img src = {Exit}
            onClick = {() => {setMobileSidebarOpen(!mobileSidebarOpen)}}
            className = {styles.Exit}
            />
            <div style = {{height: "60px"}} />
            <ItemSegment 
                title = {`Quick Guide`} 
                items = {QG} 
                open = {true} 
                page = {page}/>
            <ItemSegment 
                title = "Fundamentals" 
                open = {false}
                items={NonTechnicalItems} 
                page = {page}/>
            <ItemSegment 
                title = {`Example Code`} 
                open = {false} 
                items = {CE} 
                page = {page}/>
            <ItemSegment 
                title = "Performance Benchmarks" 
                open = {false} 
                items = {Benchmarks} 
                page = {page}/>
            <ItemSegment 
                title = "Video Guides" 
                open = {false}
                items={VideoGuideItems} 
                page = {page}/>
            <div className = {styles.BottomContainer}>
                <Link style = {{textDecoration: 'none'}} to = "/contribute">
                    <h2>Contributor Program</h2>
                </Link>

                <img onClick={() => { window.open("https://t.me/+b8ZbMRFX1xEyYzE5", "_blank", "noopener,noreferrer"); }}  src = {tg} />
                <img style = {{width: "40px", height: "40px"}} onClick={() => { window.open("https://x.com/honeybadgerweb3", "_blank", "noopener,noreferrer"); }}  src = {xlogo} />
            </div>
        </div>
    )
}
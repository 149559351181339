import { Link } from "react-router-dom";
import cpage from '../../contributorpage.module.css';
import { HoneyBadgerModelShinyDisplay } from "../../../Models/HoneyBadgerModel";
import { Canvas } from "@react-three/fiber";

import about from '../../../../assets/about.png';
import audit from '../../../../assets/audit.png';
import token from '../../../../assets/Token2d.svg'
import wanted from '../../../../assets/wanted.png'
import tglogo from '../../../../assets/tglogo.png'
import builder from '../../../../assets/builder.png';
import magician from '../../../../assets/magician.png'
import hackathon from '../../../../assets/hackathon.png';

export const Buttons = [
    undefined,
    <Link to = "/audit">
        <button style = {{marginTop: "35px"}} className = "glowingButton"> Start Auditing</button>
    </Link>,

    <Link to = "/build">
        <button style = {{marginTop: "35px"}} className = "glowingButton">Start Building</button>
    </Link>,
    <div className = {cpage.Row} style = {{paddingBottom: "60px"}}>
        <button onClick={() => window.open('https://ethereum-magicians.org/t/erc-7844-consolidated-dynamic-storage-cds/22217', '_blank')} style = {{marginTop: "35px"}} className = "glowingButton">➡️ View Proposal</button>
        <button onClick={() => { window.open("https://docs.google.com/forms/d/e/1FAIpQLSdm2HCKprVq6g0AoQAwaOwW7jl-oGUvaNn75ai9s7GXfYpdxg/viewform?usp=dialog", "_blank", "noopener,noreferrer"); }} style = {{marginTop: "35px"}} className = "glowingButton">➡️ Submit Review</button>
    </div>,
    <button style = {{marginTop: "35px"}} className = "glowingButton">Coming Soon!</button>,
    undefined,
]

export const mobileStyle = {
    width: "100vw",
    height: "50vh",
}

export const desktopStyle = {
  width: "100vw",
  height: "50vh",
  marginTop: "-10vh"
};



export const CPageBadgerScene = () => {

    let BADGER_POSITION = 4.3;

    if(window.innerWidth < 900) BADGER_POSITION = 4;


    return(
        <Canvas style = {(window.innerWidth > 900) ? desktopStyle : mobileStyle}>
            <pointLight position = {[0,0,6]} intensity = {2} />
            <HoneyBadgerModelShinyDisplay position = {[0,0,BADGER_POSITION ]}/>
        </Canvas>
    )
}

export const CPageOptions = ({setPage}) => {
    return(
        <>
            <h2  style = {{marginTop: "100px", color: 'white'}}>Learn more!</h2>
            <div className = {cpage.Row} style = {{marginTop: '0'}}>
                <div className = {`${cpage.Box} ${cpage.Gold}`} onClick = {() => {setPage(7);  window.scrollTo({ top: 0, behavior: 'smooth' })}}>

                    <img src = {wanted} className = {cpage.Image} />
                    <h3 className = {cpage.H3}>Task Bounties</h3>

                </div>

                <div className = {cpage.Box} onClick = {() => {
                    setPage(1) 
                    window.scrollTo({ top: 0, behavior: 'smooth' }); }}>
                
                    <img src = {audit} className = {cpage.Image} />
                    <h3 className = {cpage.H3}>Audit</h3>

                </div>

                <div className = {cpage.Box} onClick = {() => {setPage(2); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>

                    <img src = {builder} className = {cpage.Image} />
                    <h3 className = {cpage.H3}>Build</h3>

                </div>

                <div className = {cpage.Box} onClick = {() => {setPage(3); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>

                    <img src = {magician} className = {cpage.Image} />
                    <h3 className = {cpage.H3}>ERC-7844</h3>

                </div>

            </div>


            <div className = {cpage.Row} style = {{marginBottom: "100px"}}>

                <div className = {cpage.Box} onClick = {() => {setPage(4); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>

                    <img src = {hackathon} className = {cpage.Image} />
                    <h3 className = {cpage.H3}>Hackathon</h3>

                </div>

                <div className = {cpage.Box}  onClick = {() => {setPage(5); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>

                    <img src = {token} className = {cpage.Image} />
                    <h3 className = {cpage.H3}>Rewards</h3>

                </div>

                
                <div className = {cpage.Box} onClick = {() => {setPage(6); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>

                    <img src = {about} className = {cpage.Image} />
                    <h3 className = {cpage.H3}>About HoneyBadger</h3>

                </div>

                <div 
                    className={cpage.Box} 
                    onClick={() => { 
                        window.open("https://t.me/+b8ZbMRFX1xEyYzE5", "_blank", "noopener,noreferrer"); 
                    }}
                >
                    <img src={tglogo} className={cpage.Image} />
                    <h3 className={cpage.H3}>Join</h3>
                </div>
            </div>
        </>
    )
}

export const CPageBottomText = () => {
    return(
        <>
            <p className={cpage.PG}>
                <br/><br/><br/>
                <b style = {{color: "white"}}>What should I do? </b>
                <br/>
                It's tricky to come up with a new idea from scratch, and we don't expect you take on that burden
                just to participate.  Check out 
                "task bounties" for tons of ideas you can build in just a few hours, or a single weekend.
                <br/><br/>

                <b style = {{color: "white"}}>Multiple Submissions</b>
                <br/>
                To prevent abuse, we limit form submissions to one per user.  However, you can submit multiple 
                projects by simply editing your original submission and adding the new data.
            </p>
        </>
    )
}
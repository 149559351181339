import { H, H2, Li, P, P2, S, Sm, Tab, Ul, White } from "../Common/CommonElements";
import PageLayout from "../Common/PageLayout";
import styles from '../Common/page.module.css';
import put from '../../../../assets/carbon/put.png'
import get from '../../../../assets/carbon/get.png'
import pageStyles from '../Common/page.module.css';


export default function Page_2_2({mobileMode})
{
    return(
        <PageLayout mobileMode={mobileMode}>
            <h2 className={styles.PageHeader}>
                HoneyBadger: Modifying and Retrieving Data
            </h2>
            <P2>
                <Tab /> <b>Modifying Data</b>
                <br/><br/>
                <Tab/>
                Use the <i>put </i> function to modify storage in HoneyBadger. 
                <br/><br/>
            </P2>

            <img style = {{marginTop: "20px"}} className = {pageStyles.Img} src = {put} />

            <P2>
            <br/><br/>
                <Tab /> <b>Retrieving Data</b>
                <br/><br/>
                <Tab/>
                Use the <i>get </i> function to read storage in HoneyBadger. 
                <br/><br/>
            </P2>

            <img style = {{marginTop: "20px"}} className = {pageStyles.Img} src = {get} />

        </PageLayout>
        
    )
}
import { supabase } from "../../../utils/supabaseclient";

export const fetchPastTasks = async ({ userId, setPastTasks }) => {
    if (!userId) return;

    const { data, error } = await supabase.rpc('fetch_past_tasks', {
        p_contributor_id: userId
    });

    if (error) {
        console.error("Error fetching past tasks:", error);
        return;
    }

    let adjustedData = data.filter((entry) => entry.completed == true)

    setPastTasks(adjustedData);
};
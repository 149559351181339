import { supabase } from "../../../utils/supabaseclient";

export const fetchCurrentTask = async ({ userId, setCurrentTask }) => {
    if (!userId) return;

    // Fetch current task ID from contributors
    const { data: contributorData, error: contributorError } = await supabase
        .from('contributors')
        .select('current_task_id')
        .eq('id', userId)
        .single();

    if (contributorError || !contributorData?.current_task_id) {
        console.error('Error fetching current task from contributors:', contributorError);
        setCurrentTask(null);
        return;
    }

    const currentTaskId = contributorData.current_task_id;

    // Fetch all active claimed task instances for the user
    const { data: claimedTasks, error: claimedTasksError } = await supabase
        .from('claimed_tasks')
        .select('task_id, task_instance_id, tasks(title, description)')
        .eq('contributor_id', userId)
        .eq('task_id', currentTaskId)
        .eq('completed', false);

    if (claimedTasksError || !claimedTasks || claimedTasks.length === 0) {
        console.error('Error fetching current task details:', claimedTasksError);
        setCurrentTask(null);
        return;
    }

    // Fetch all submitted task instance IDs for the user
    const { data: submittedTasks, error: submittedTasksError } = await supabase
        .from('task_submissions')
        .select('task_instance_id')
        .eq('contributor_id', userId);

    if (submittedTasksError) {
        console.error('Error fetching submitted tasks:', submittedTasksError);
    }

    const submittedInstanceIds = new Set(submittedTasks?.map(sub => sub.task_instance_id) || []);

    // Filter out tasks that have already been submitted
    const unsubmittedTask = claimedTasks.find(task => !submittedInstanceIds.has(task.task_instance_id));

    if (unsubmittedTask) {
        setCurrentTask({
            id: unsubmittedTask.task_id,
            instanceId: unsubmittedTask.task_instance_id,
            title: unsubmittedTask.tasks?.title || "Unknown Task",
            description: unsubmittedTask.tasks?.description || "",
        });
    } else {
        setCurrentTask(null);
    }
};

import { supabase } from "../../../utils/supabaseclient";

export const fetchSentInvites = async ({ currentTask, userId, setSentInvites }) => {
    if (!currentTask) return;

    const { data, error } = await supabase
        .from("task_invitations")
        .select(`
            invitee_id, 
            invited_at, 
            contributors!task_invitations_invitee_id_fkey(telegram)
        `)
        .eq("task_id", currentTask.id)
        .eq("inviter_id", userId)
        .eq("task_instance_id", currentTask.instanceId)
        .eq("status", "pending")
        .order("invited_at", { ascending: false });

    if (error) {
        console.error("Error fetching sent invites:", error);
        return;
    }

    setSentInvites(data);
};
import Ethlogo from '../../3D/EthLogo';
import { Link } from 'react-router-dom';
import { Canvas } from '@react-three/fiber';
import styles from '../../CSS/SectionX.module.css';
import ReadDocs from '../../../../../assets/ReadDocs.svg';
import Discussion from '../../../../../assets/Discussion.svg';


export default function SubSection2()
{
    return(
        <div 
            id = "SectionX_Section2Container"
            className = {styles.SectionX_Section2Container}
        >
            <h2 className = {styles.Header3}>
                Pioneering a Convenient Approach to 
                Interoperability and Adaptability
            </h2>

            <div className = {styles.ERCContainer}>

                <div className={styles.NiceColumn2}>
                    <Canvas style = {{width: '200px', height: '200px', margin: 0, padding: 0}}>
                        <Ethlogo position = {[0, -3, 1]}/>
                    </Canvas>
                    <h2 className={`${styles.Header4} TrapBold`}>ERC-7844</h2>
                    <h2 className={`${styles.Header4Sub2} HN`}>
                    Consolidated Dynamic Storage
                    </h2>
                </div>

                <div className={styles.NiceColumn} style = {{marginTop: '20px'}}>
                    <h2 className={`${styles.Header2} HN`}>Cutting-edge infra, for free.</h2>

                    <p className = {`${styles.Pg2} HN`}>
                    Our methodology (formalized in ERC-7844) introduces deterministic <i>in-place 
                    storage adaptability </i> by leveraging hash-segregated storage spaces populated 
                    by custom extendible structs.
                    
                    </p>
                    
                    <a  
                    href="https://ethereum-magicians.org/t/erc-7844-consolidated-dynamic-storage-cds/22217" 
                        target="_blank" 
                        rel="noopener noreferrer"
                    style = {{marginTop: "40px", width: "100%", margin: 0, textDecoration: 'none'}}>
                        <div className={styles.NiceRowHoverable} >
                            <img src = {Discussion} className = {styles.Img2}/>
                            <h2 className={`${styles.Header2Hoverable} HN`}>ERC-7844 Discussion</h2>
                        </div>
                    </a>

                    <Link to = {{
                        pathname: '/docs',
                        search: '?section=1.4',
                        state: {section: 1.4}
                    }} style = {{marginTop: "40px", width: "100%", margin: 0, textDecoration: 'none'}}>
                        <div className={styles.NiceRowHoverable}>
                            <img src = {ReadDocs} className = {styles.Img2}/>
                            <h2 className={`${styles.Header2Hoverable} HN`}>Read the Docs</h2>
                        </div>
                    </Link>
                </div>
            </div>
        </div>

    )
}
import { deleteTask } from '../../DBActions/Admin/deleteTask';
import '../css/AdminDashboard.css';

export default function DisplayExistingTasks({
    filteredTasks,
    setTasks,
    setFilteredTasks,
    setGroups
})
{
    return(
        <div className='task-scroll-container'>
            {filteredTasks.map((task) => (
                <div key={task.id} className='task-card'>
                    <strong className='task-title'>{task.title}</strong>
                    <p className='task-description'>{task.description}</p>
                    <p className='task-meta'><strong>Points:</strong> {task.points}</p>
                    <p className='task-meta'><strong>Group:</strong> {task.group_identifier}</p>
                    <p className='task-meta'><strong>Subgroup:</strong> {task.sub_identifier}</p>
                    <button className='task-delete' onClick={() => deleteTask({id: task.id, setTasks, setFilteredTasks, setGroups})}>Delete</button>
                </div>
            ))}
        </div>
    )
}
import '../../css/keyFonts.css'
import '../../css/TextStyles.css'
import '../../css/glowButton.css'
import user1 from '../../assets/user1.svg'
import user2 from '../../assets/user2.svg'
import { useEffect, useState } from 'react';
import ContributorPageHeader from './Header';
import { LoginButton } from '../../utils/auth';
import cpage from './contributorpage.module.css';
import UserPage from './SupportingPages/UserPage';
import Animations from './ContributorPageSupporting/animations';
import { fetchUser } from './ContributorPageSupporting/fetchUser';
import TaskList from './SupportingPages/components/Tasks/TaskList';
import { fetchCurrentSprint } from './DBActions/fetchCurrentSprint'
import { handleSubmit } from './ContributorPageSupporting/handleSubmit';
import { CPAGECORETEXT, TextSections, Titles } from './SupportingPages/components/TextSections';
import { CPageBadgerScene, CPageOptions } from './SupportingPages/components/ContributorPageElements';
import tglogo from '../../assets/tglogo.png'
import { Link, useNavigate } from 'react-router-dom'
import BulletinBoard from './SupportingPages/components/BulletinBoard'
import ctst from '../../utils/CoreTenets.module.css';


export default function ContributorPage()
{
    const [page, setPage] = useState(0);
    const [user, setUser] = useState(null);
    const [update, setUpdate] = useState(0)
    const [inputTG, setInputTG] = useState('');
    const [telegram, setTelegram] = useState("");
    const [loading, setLoading] = useState(true);
    const [ethAddress, setEthAddress] = useState("");
    const [referrerTG, setReferrerTG] = useState(null)
    const [needsInfo, setNeedsInfo] = useState(false);
    const [inputAddress, setInputAddress] = useState('')
    const [updateTask, setUpdateTask] = useState(false);
    const [userHovered, setUserHovered] = useState(false);
    const [currentSprint, setCurrentSprint] = useState(null)
    const [userPageVisible, setUserPageVisible] = useState(false);

    useEffect(() => 
    {
        Animations({userPageVisible});
    }, [userPageVisible])


    useEffect(() => 
    {
        fetchUser({setLoading, setUser, setNeedsInfo, setTelegram, setEthAddress})
    }, []);

    useEffect(() => {

        //fetch and set current sprint
        if(user && !needsInfo && !currentSprint)
        {
            fetchCurrentSprint({setSprint: setCurrentSprint});
        }

    }, [user, needsInfo]);

    return(
        <>
            <UserPage 
                update = {update}
                setUpdate = {setUpdate}
                telegram = {telegram} 
                ethAddress = {ethAddress} 
                userId={user?.id}
                setTelegram={setTelegram}
                setEthAddress={setEthAddress}
                userPageVisible={userPageVisible}
                setUserPageVisible = {setUserPageVisible}
                sprint = {currentSprint}
                updateTask={updateTask}
                setUpdateTask = {setUpdateTask}
                />
            <div className = {cpage.Container} id = "ContributorPageMain">

                { (user && !needsInfo) &&
                    <div className = {cpage.BoxContainer}>
                        {
                            (window.innerWidth > 900) && 
                            <img  
                                onClick={() => { window.open("https://t.me/+b8ZbMRFX1xEyYzE5", "_blank", "noopener,noreferrer"); }} 
                                src = {tglogo} style = {{width: "82.5px", height: "82.5px", cursor: "pointer"}}/>
                        }
                        <div className = {cpage.UserBox} 
                            onClick = {() => {setUserPageVisible(true)}}
                            onPointerOver={() => {setUserHovered(true)}}
                            onPointerOut={() => {setUserHovered(false)}}>
                            <img src = {(userHovered) ? user2 : user1} /> 
                        </div>
                    </div>
                }
                <ContributorPageHeader />
                <CPageBadgerScene />

                <h2 className = {`Trap ${cpage.H2} ShinyText`}>
                    {page == 0 ? `Be a HoneyBadger Builder!` : Titles[page - 1]}
                </h2>
                {
                    (user && !needsInfo && !loading) && (
                        <>

                            <p className = {cpage.PG} dangerouslySetInnerHTML={{__html: TextSections[page]}}/>

                            { (page == 0) && 
                                <div style = {{
                                    width: "80%", 
                                    display: 'flex', 
                                    flexDirection: "row", 
                                    gap: "50px", 
                                    justifyContent: 'center',
                                    alignItems: "center",
                                    marginTop: "40px",
                                }}>
                                    <p className = {cpage.PGX}>
                                    
                                        <span style = {{color: 'white'}}>How it Works:</span> <br/>
                                        1) Choose or create a project.<br/>
                                        2) Invite collaborators.<br/>
                                        3) Submit project.  <br/><br/>
                                        Post about your work for up to 1.5x points!
                                    
                                    </p>
                                </div>
                            }
                        </>
                    )
                }


                {!user && !loading  &&
                <>
                    <p className = {cpage.PG}>
                        {CPAGECORETEXT}
                    </p>
                    <LoginButton />
                </>}

                {user && needsInfo && !loading && (
                    <>
                        <p className = {cpage.PG}>
                            {CPAGECORETEXT}

                            <br/><br/>
                            Last step!  Complete your registration by providing your ETH address and Telegram username.
                        </p>

                        <form 
                            onSubmit={(e) => {handleSubmit({
                                e, 
                                user,
                                inputTG, 
                                inputAddress, 
                                referrerTG,  
                                setTelegram, 
                                setEthAddress, 
                                setNeedsInfo
                            })}} 
                            className={cpage.FormContainer}
                        >
                            <div className = {cpage.Col2}>
                                <div className = {cpage.Col}>
                                    <label>
                                        Telegram Username:
                                    </label>
                                    <input
                                            placeholder='@...'
                                            type="text"
                                            value={inputTG ?? ''}
                                            onChange={(e) => setInputTG(e.target.value)}
                                            required
                                        />
                                </div>

                                <div className = {cpage.Col}>
                                    <label>
                                        Ethereum Address:
                                    </label>
                                    <input
                                            type="text"
                                            value={inputAddress ?? ''}
                                            onChange={(e) => setInputAddress(e.target.value)}
                                            required
                                        />
                                </div>

                                {/* New field for referral */}
                                <div className = {cpage.Col}>
                                    <label>
                                        Referrer’s Telegram (Optional):
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="@referrerUsername"
                                        value={referrerTG ?? ''}
                                        onChange={(e) => setReferrerTG(e.target.value)}
                                    />
                                </div>

                                <button type="submit">Complete Registration</button>
                            </div>
                        </form>
                    </>
                )}

                {(user && !needsInfo && !loading) &&
                    (
                        <>
                            <TaskList 
                                title = {"Contribution Opportunities"} 
                                update = {update} 
                                setUpdate={setUpdate}
                                setUpdateTask = {setUpdateTask}
                            />

                            <div style = {{display: "flex", flexDirection: 'row', alignItems: 'center', gap: "20px"}}>
                                <div className = {cpage.TasksAndProfile} 
                                onClick = {() => {setUserPageVisible(true)}}
                                onPointerOver={() => {setUserHovered(true)}}
                                onPointerOut={() => {setUserHovered(false)}}>
                                    Your Tasks and Profile 
                                </div>
                                <Link 
                                    style={{textDecoration: 'none'}}
                                    to = {{
                                        pathname: '/docs',
                                        search: '?section=2.1',
                                        state: {section: 2.1}
                                    }}
                                >
                                    <div className = {cpage.TasksAndProfile}>
                                        Sample Code
                                    </div>
                                </Link>

                                <div 
                                
                                onClick={() => { window.open("https://github.com/wisecameron/honeybadgerframework", "_blank", "noopener,noreferrer"); }}
                                className = {cpage.TasksAndProfile}>
                                    Official Repo
                                </div>
                            </div>
                            


                            <CPageOptions setPage = {setPage}/>

                            <BulletinBoard />
                            {/* <CPageBottomText /> */}

                        </>
                    )
                }

            </div>
        </>
    )
}
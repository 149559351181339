import { supabase } from "../../../../utils/supabaseclient";

export const fetchTasks = async ({setTasks, setFilteredTasks, setGroups}) => {
    const { data, error } = await supabase.from('tasks').select('*');
    if (!error) 
    {
        setTasks(data);
        setFilteredTasks(data);

        // Extract unique task groups for filtering
        const uniqueGroups = [...new Set(data.map(task => task.group_identifier))];
        setGroups(uniqueGroups);
    }
};
import '../../index.css'
import '../../css/docPage.css'
import Sidebar from "./Sidebar/Sidebar";
import { useEffect,  useState } from "react";
import PageManager from "./Pages/PageManager";
import { AspectManager2 } from "../Helpers/ClipManager";
import MobileSidebar from './Sidebar/MobileSidebar/MobileSidebar';
import Hamburger from './Sidebar/MobileSidebar/Hamburger';
import { useLocation, useSearchParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';


export default function DocumentationPage({preSelectedPage})
{
    const [docked, setDocked] = useState(false);
    const [height, setHeight] = useState(AspectManager2(1200));
    const [mobileMode, setMobileMode] = useState(window.innerWidth < 1000);
    const [page, setPage] = useState(null);
    const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);


    const PASS_ENABLED = false;
    const [searchParams] = useSearchParams();

      // Resize Handler
    const HandleResize = () => 
    {
      const isMobile = window.innerWidth < 1000;
      setMobileMode(isMobile);
    };

    useEffect(() =>
    {
      document.body.style.overflowY = 'scroll';
  
      window.addEventListener('resize', HandleResize);
      return () => 
      {
        window.removeEventListener('resize', HandleResize)
        document.body.style.overflowY = '';
      }
      
    }, [])


    useEffect(() => {
      
      if(!firstLoad) return;
      const section = searchParams.get('section') || 7.1 //default page
      setPage(section)
      setFirstLoad(false)
    }, [page, firstLoad])
  
    if(!page) return null;

    return(
      <>
        <div style = {{display:'flex', flexDirection: 'row', width: "100vw", height: "100vh"}} >
          { (mobileMode === false && !isMobile) && 
            <>
                <Sidebar 
                  page = {page} 
                  setPage = {(v) => setPage(v)} 
                  docked = {docked} 
                  setDocked = {(v) => setDocked(v)}
                  height = {height} 
                  setHeight = {(v) => {setHeight(v)}}
                />
              </>
          }
          { (mobileMode === true || isMobile) && 
            <>
              <Hamburger 
                mobileSidebarOpen={mobileSidebarOpen}
                setMobileSidebarOpen={setMobileSidebarOpen}
              />
              <MobileSidebar 
                page = {page}
                setPage = {setPage}
                mobileSidebarOpen={mobileSidebarOpen}
                setMobileSidebarOpen={setMobileSidebarOpen}
              />
            </>
          }
          <PageManager 
          mobileSidebarOpen = {mobileSidebarOpen}
          setMobileSidebarOpen = {setMobileSidebarOpen}
          mobileMode = {mobileMode} 
          page = {page} />
        </div>
      </>

    )
}
import styles from './CoreTenets.module.css';

export default function CoreTenets()
{
    return(
        <div className={styles.PC}>
            <div className = {styles.Container}>
                <div className={styles.Cont}>
                    <h2>Less Code</h2>
                    <p>Did you know every 1,000 lines of code introduces more than 10 
                    critical bugs on average?</p>
                </div>

                <div className={styles.Cont}>
                    <h2>Safe Upgrades</h2>
                    <p>
                        Manual storage management is too risky for high-stakes 
                        financial systems.  Reducing the risk surface saves
                        time and money.
                    </p>
                </div>

                <div className={styles.Cont}>
                    <h2>Business Logic First</h2>
                    <p>
                        Supporting systems are inconsistent and manually-implemented.  
                        Let's start with a strong foundation of infrastructure and prioritize new ideas instead.
                    </p>
                </div>
            </div>

            <div className = {styles.Container}>
                <div className={styles.Cont}>
                    <h2>Un-Siloed Data</h2>
                    <p>
                        Siloed data complicates dependencies and limits how contracts 
                        interoperate.  It's time to consolidate storage and evolve 
                        cross-contract systems.
                    </p>
                </div>

                <div className={styles.Cont}>
                    <h2>Standardized Access</h2>
                    <p>
                        Getters, putters, and adapters just introduce extra bytecode, and references can break
                        with upgrades.  With consolidated data, they are no longer needed.
                    </p>
                </div>

                <div className={styles.Cont}>
                    <h2>In-Place Upgradeability</h2>
                    <p>
                        Expand and create storage structures without re-deployment.  
                        It's easier, cheaper, and safer.
                    </p>
                </div>
            </div>
        </div>
    )
}
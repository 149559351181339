import { useSpring, animated } from "react-spring"
import Page_1 from "./Page_1/Page_1"
import Page_2_1 from "./Page_2/Page_2_1"
import Page3_1 from "./Page_3/Page3_1"
import Page3_2 from "./Page_3/Page3_2"
import Page3_3 from "./Page_3/Page3_3"
import Page3_4 from "./Page_3/Page3_4"
import Page3_5 from "./Page_3/Page3_5"
import Page1_3 from "./Page_1/Page_1_3"
import Page1_4 from "./Page_1/Page_1_4"
import Page_5_2 from "./Page_5/Page_5_2"
import Page_1_2 from "./Page_1/Page_1_2"
import Page_4_1 from "./Page_4/Page_4_1"
import Page_5_1 from "./Page_5/Page_5_1";
import Page_6_1 from "./Page_6/Page_6_1";
import Page_6_2 from "./Page_6/Page_6_2";
import Page_4_2 from "./Page_4/Page_4_2"
import Page_2_2 from "./Page_2/Page_2_2"
import Page_2_3 from "./Page_2/Page_2_3"
import Page_6_3 from "./Page_6/Page_6_3"
import Page_6_4 from "./Page_6/Page_6_4"
import Page_7_1 from "./Page_7/Page_7_1"
import Page_7_2 from "./Page_7/Page_7_2"
import Page_7_3 from "./Page_7/Page_7_3"



export default function PageManager({mobileMode, page})
{
    if(page === 0 || page === undefined) return;

    if(page == 1.1) return <Page_1 mobileMode = {mobileMode} />
    if(page == 1.2) return <Page_1_2  mobileMode={mobileMode} />
    if(page == 1.3) return <Page1_3 mobileMode={mobileMode} />
    if(page == 1.4) return <Page1_4 mobileMode={mobileMode} />

    if(page == 2.1) return <Page_2_1 mobileMode = {mobileMode}/>
    if(page == 2.2) return <Page_2_2 mobileMode={mobileMode} />
    if(page == 2.3) return <Page_2_3 mobileMode={mobileMode} />

    if(page == 3.1) return <Page3_1 />
    if(page == 3.2) return <Page3_2 />
    if(page == 3.3) return <Page3_3 />
    if(page == 3.4) return <Page3_4 />
    if(page == 3.5) return <Page3_5 />

    if(page == 4.1) return <Page_4_1 />
    if(page == 4.2) return <Page_4_2 />
    
    if(page == 5.1) return <Page_5_1 />
    if(page == 5.2) return <Page_5_2 />

    if(page == 6.1) return <Page_6_1 mobileMode = {mobileMode}/>
    if(page == 6.2) return <Page_6_2 mobileMode = {mobileMode}/>
    if(page == 6.3) return <Page_6_3 mobileMode = {mobileMode}/>
    if(page == 6.4) return <Page_6_4 mobileMode = {mobileMode}/>

    if(page == 7.1) return <Page_7_1 mobileMode={mobileMode} />
    if(page == 7.2) return <Page_7_2 mobileMode={mobileMode} />
    if(page == 7.3) return <Page_7_3 mobileMode={mobileMode} />
}

export const PageWrapper = (props) =>
{
    let d = props.docked == "true" ? true : false;

    const anim = useSpring({

        from: {
            width: "67vmax",
            background: "#151515", 
            zIndex: 50, 
            position: "absolute", 
            marginLeft: "26.75vmax", 
            marginTop: "6vmax", 
            border: "1px solid #505050",
            opacity: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: 'center',
            textAlign: 'center',
            paddingBottom: "10vmax",
            color: "white"},
        to: {
            width: (d) ? "85.5vmax":"67vmax",  
            background: "#151515", 
            zIndex: 50, 
            position: "absolute", 
            marginLeft: (d) ? "10vmax" :"26.75vmax", 
            marginTop: "6vmax",
            border: "1px solid #505050",
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: 'center',
            alignItems: 'center',
            paddingBottom: "10vmax",
            borderRadius: "5px",
            color: "white"},
        config: {duration: 500},
        delay: 250
    });

    return(
        <>
            <animated.div style = {anim} {...props}>
                {props.children}
            </animated.div>
        </>
    )
}
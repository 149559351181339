import '../../css/TaskItem.css';

export default function TaskItem({ task, onClaim, currentTask, setUpdate, update }) {
    const isTaskFull = task.total_entries + task.current_completions >= task.completions_limit;
    const hasClaimedTask = !!currentTask; // User has an ongoing task

    return (
        <div className='task-item'>
            <h3 className='task-h3'>{task.title}</h3>
            <p className = 'desc'>{task.description}</p>
            <div className='task-meta'>
                <span className = 'points'>Max Points: {task.points}</span>
                <span className = 'points'>Group: {task.group_identifier} - {task.sub_identifier}</span>
            </div>
            {/* <div className='task-meta'>
                <span>Claimed by: {task.total_entries}</span>
                <span>Completed: {task.current_completions}</span>
            </div> */}
            <button 
                onClick={() => {
                    onClaim(task.id);
                }} 
                disabled={hasClaimedTask || isTaskFull}
                className={hasClaimedTask ? "claimed-task" : isTaskFull ? "full-task" : ""}
            >
                {hasClaimedTask 
                    ? "You have already claimed a task!" 
                    : isTaskFull 
                        ? "Project is Full" 
                        : "Claim Project"}
            </button>
        </div>
    );
}


import up from "../css/userPage.module.css";
import '../../../../css/TextStyles.css';
import { useEffect, useState } from "react";
import { fetchReferrer, setReferrer } from "../../DBActions/referrerActions";

export default function UserStats({
    level,
    progress,
    currentSprintPoints,
    totalSprintPoints,
    TGUpdate,
    setInputTG,
    ETHUpdate,
    setInputETH,
    telegram,
    inputTG,
    userId,
    setTelegram,
    ethAddress,
    setEthAddress,
    inputETH,
    sprint
}) {
    const [referrer, setReferrerState] = useState(null);
    const [inputReferrer, setInputReferrer] = useState("");

    useEffect(() => {
        if (userId) {
            fetchReferrer(userId, setReferrerState);
        }
    }, [userId]);

    const handleReferrerSubmit = async (e) => {
        e.preventDefault();
        await setReferrer(userId, inputReferrer, setReferrerState);
    };

    const LEVEL_TITLES = [
        "Turnip 🌱",
        "Certified Contributor 💯",
        "Core Contributor 😏",
        "Legendary Contributor ⚔️",
        `✨✨Transcendant✨✨`,
    ];

    const LEVEL_BOOSTS = [1.1, 1.2, 1.35, 1.55, 1.9];

    return (
        <>
            <div className={up.OptionContainer}>
                <h2><span style={{ fontSize: "45px" }} className="ShinyText">{level}</span></h2>
                <h2>Level {level} {LEVEL_TITLES[level - 1]} • {LEVEL_BOOSTS[level - 1]}x points</h2>
                <div className={up.progressContainer}>
                    <div className={up.progressBar} style={{ width: `${progress}%` }}></div>
                </div>
                <p>{totalSprintPoints} Total Points</p>
            </div>

            {/* Sprint & Total Points */}
            <div className={up.OptionContainer}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly", width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <h2>Your Points (Sprint {sprint})</h2>
                        <p><strong>{currentSprintPoints}</strong></p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <h2>Your Total Points</h2>
                        <p><strong>{totalSprintPoints}</strong></p>
                    </div>
                </div>
            </div>

            {/* Referrer Section */}
            <div className={up.OptionContainer}>
                {referrer ? (
                    <>
                        <h2>Your Referrer: {referrer}</h2>
                        <p>+5% points </p>
                    </>
                ) : (
                    <>
                        <h2>No referrer set</h2>
                        <p style = {{textAlign: "center", width: "90%"}}>Find a buddy in the Telegram group! Setting a referrer will net you an 
                        extra 5% points, and they'll get the same whenever you complete a task!</p>
                        <form onSubmit={handleReferrerSubmit}>
                            <input 
                                type="text" 
                                placeholder="Enter referrer's Telegram" 
                                value={inputReferrer} 
                                onChange={(e) => setInputReferrer(e.target.value)} 
                            />
                            <button type="submit">Submit</button>
                        </form>
                    </>
                )}
            </div>

            {/* Telegram Update */}
            <div className={up.OptionContainer}>
                <h2>Your Telegram Username: {telegram}</h2>
                <form onSubmit={(e) => { e.preventDefault(); TGUpdate({ inputTG, userId, setTelegram }); }}>
                    <input type="text" value={inputTG} onChange={(e) => setInputTG(e.target.value)} />
                    <button type="submit">Update</button>
                </form>
            </div>

            {/* ETH Address Update */}
            <div className={up.OptionContainer}>
                <h2>Your ETH Address: <span>{ethAddress}</span></h2>
                <form onSubmit={(e) => { e.preventDefault(); ETHUpdate({ inputETH, userId, setEthAddress }); }}>
                    <input type="text" value={inputETH} onChange={(e) => setInputETH(e.target.value)} />
                    <button type="submit">Update</button>
                </form>
            </div>
        </>
    );
}
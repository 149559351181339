import './css/AdminDashboard.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TaskCreationForm from './Admin/taskCreationForm';
import { fetchUser } from '../DBActions/Admin/fetchUser';
import TaskReviewPanel from './components/TaskReviewPanel';
import { fetchTasks } from '../DBActions/Admin/fetchTasks';
import DisplayFilterByGroup from './Admin/DisplayFilterByGroup';
import DisplayExistingTasks from './Admin/DisplayExistingTasks';
import WriteupReview from './Admin/WriteupReview';

export default function AdminDashboard() 
{
    const navigate = useNavigate();
    const [tasks, setTasks] = useState([]);
    const [title, setTitle] = useState('');
    const [group, setGroup] = useState('');
    const [points, setPoints] = useState(0);
    const [groups, setGroups] = useState([]);
    const [limit, setLimit] = useState(null);
    const [adminId, setAdminId] = useState(null)
    const [subGroup, setSubGroup] = useState('');
    const [loading, setLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const [description, setDescription] = useState('');
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState('All');


    useEffect(() => {
        fetchUser({
            setIsAdmin, 
            fetchTasks, 
            setLoading, 
            navigate,
            setFilteredTasks, 
            setTasks, 
            setGroups, 
            setAdminId
        })
    }, [navigate]);

    if (loading) return <h2>Loading...</h2>;
    if (!isAdmin) return null;

    return (
        <div className='admin-container'>
            <h2>Admin Dashboard - Task Management</h2>

            {/* Task Creation Form */}
            <TaskCreationForm 
                title = {title}
                description = {description}
                points = {points}
                group = {group}
                subGroup = {subGroup}
                limit = {limit}
                setTasks = {setTasks}
                setFilteredTasks = {setFilteredTasks}
                setGroups = {setGroups}
                setTitle = {setTitle}
                setDescription = {setDescription}
                setPoints = {setPoints}
                setGroup = {setGroup}
                setSubGroup = {setSubGroup}
                setLimit = {setLimit}
            />

            {/* Task Filtering Dropdown */}
            <label className='task-filter-label'>Filter by Group:</label>
            <DisplayFilterByGroup 
                setSelectedGroup = {setSelectedGroup}
                setFilteredTasks = {setFilteredTasks}
                tasks = {tasks}
                selectedGroup = {selectedGroup}
                groups={groups}
            />

            {/* Task List with Horizontal Scrolling */}
            <h3>Existing Tasks</h3>
            <DisplayExistingTasks 
                filteredTasks = {filteredTasks}
                setTasks = {setTasks}
                setFilteredTasks = {setFilteredTasks}
                setGroups = {setGroups}
            />

            <WriteupReview adminId = {adminId} />

            <TaskReviewPanel />
        </div>
    );
}
import up from '../css/userPage.module.css';
import { declineInvite } from '../../DBActions/rejectInvite';

export default function TaskInvites({ taskInvites, acceptInvite, userId, update, setUpdate, fetchInvites }) {
    return (
        <>
            {/* Task Invites Section (Scrollable) */}
            {taskInvites.length > 0 && (
                <div className={up.InviteScrollContainer}>
                    <h3 style={{ color: 'white' }}>Pending Task Invitations</h3>
                    <div className={up.InviteRow}>
                        {taskInvites.map((invite, index) => (
                            <div className={up.InviteCard} key={`${invite.id}-${index}`}>
                                <p style={{ color: 'white' }}>
                                    <strong>{invite.tasks?.title || "Untitled Task"}</strong>
                                </p>  
                                <button onClick={() => acceptInvite({ inviteId: invite.id, userId, update, setUpdate })}>
                                    Accept
                                </button>
                                <button 
                                    onClick={async () => {
                                        await declineInvite({ inviteeId: userId, inviteId: invite.id, taskInstanceId: invite.task_instance_id, fetchInvites });
                                        setUpdate(update + 1); // Refresh UI
                                    }}
                                    style={{ marginLeft: '10px', backgroundColor: 'red', color: 'white' }}
                                >
                                    Decline
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}




export const filterTasksByGroup = ({group, setSelectedGroup, setFilteredTasks, tasks}) => 
{
    setSelectedGroup(group);
    if (group === 'All') {
        setFilteredTasks(tasks);
    } else {
        setFilteredTasks(tasks.filter(task => task.group_identifier === group));
    }
};
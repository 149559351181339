import { supabase } from "../../../utils/supabaseclient";

export const fetchGroups = async ({setAvailableGroups}) => {
    const { data, error } = await supabase
        .from('tasks')
        .select('group_identifier')
        .order('group_identifier', { ascending: true });

    if (error) {
        console.error('Error fetching task groups:', error);
        return;
    }

    const uniqueGroups = [...new Set(data.map(task => task.group_identifier))];
    setAvailableGroups(uniqueGroups);
};
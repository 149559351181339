import gsap from "gsap"

export default function Animations({userPageVisible})
{
    if(userPageVisible === true)
    {
        gsap.timeline({})
        .to("#ContributorPageMain", {opacity: 0, pointerEvents: "none"})
        .to("#ContributorPageMain", {display: "none"}, "<")
        .to("#UserPage", {display: "flex"})
        .to("#UserPage", {opacity: 1, pointerEvents: "all"}, "<")
    }
    else
    {
        gsap.timeline({})
        .to("#UserPage", {opacity: 0, pointerEvents: "none"})
        .to("#UserPage", {display: "none"}, "<")
        .to("#ContributorPageMain", {display: "flex"})
        .to("#ContributorPageMain", {opacity: 1, pointerEvents: "all"}, "<")
    }
}
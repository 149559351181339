import { supabase } from "../../../utils/supabaseclient";

export const cancelTask = async ({ currentTask, userId, setCurrentTask, setUpdate, update }) => {
    if (!currentTask) {
        alert("You don't have an active task to cancel.");
        return;
    }

    const { data, error } = await supabase.rpc('cancel_claimed_task', {
        p_contributor_id: userId
    });

    if (error || !data || data.startsWith('Error:')) {
        alert(data);
        console.error('Cancel task error:', error || data);
        return;
    }

    alert(data);
    setCurrentTask(null);
    setUpdate(update + 1);
};
import { supabase } from "../../../utils/supabaseclient";

export const handleCreateCustomTask = async ({
    userId,
    currentTask,
    customTitle,
    customDescription,
    setCustomDescription,
    setCustomTitle,
    update,
    setUpdate
}) => {
    if (!userId) {
        alert('You need to be logged in to create a task.');
        return;
    }

    if (currentTask) {
        alert('You must finish or unclaim your current task first.');
        return;
    }

    if (customTitle.length > 100 || customDescription.length > 500) {
        alert("Title must be under 100 characters and description under 500.");
        return;
    }

    // Call the RPC function
    const { data, error } = await supabase.rpc('create_custom_task', {
        p_contributor_id: userId,
        p_title: customTitle,
        p_description: customDescription
    });

    if (error) {
        console.error('Error creating custom task:', error);
        alert('An error occurred while creating the task.');
        return;
    }

    if (data.startsWith('Error:')) {
        alert(data.replace('Error: ', ''));
        console.log("FLOOGUS")
        return;
    }

    alert('Custom task created and claimed successfully!');
    setCustomTitle("");
    setCustomDescription("");
    setUpdate(update + 1);
};
import { useEffect, useState } from 'react';
import up from './css/userPage.module.css';
import '../../../css/TextStyles.css'
import { ETHUpdate, TGUpdate } from './components/UserPageHelpers';
import { fetchCurrentTask } from '../DBActions/fetchCurrentTask';
import { fetchTaskInvites } from '../DBActions/fetchTaskInvites';
import { cancelTask } from '../DBActions/cancelTask';
import { fetchSentInvites } from '../DBActions/fetchSentInvites';
import { fetchSprintPoints } from '../DBActions/fetchSprintPoints';
import { sendInvite } from '../DBActions/sendInvite';
import { acceptInvite } from '../DBActions/acceptInvite';
import { submitTask } from '../DBActions/submitTask';
import TaskInvites from './UserPageSections/TaskInvites';
import OptionContainer from './UserPageSections/OptionContainer';
import UserStats from './UserPageSections/UserStats';
import TaskContributors from './UserPageSections/TaskContributors';
import { fetchTaskContributors } from '../DBActions/fetchTaskContributors';
import { fetchPastTasks } from '../DBActions/fetchPastTasks';
import { calculateLevel } from '../ContributorPageSupporting/calculateLevel';
import { submitOnlineLink } from '../DBActions/submitOnlineLink';
import InfoScene from '../../Scene/InfoScene/InfoScene';

export default function UserPage({
    telegram, 
    ethAddress, 
    userId, 
    setTelegram, 
    setEthAddress, 
    setUserPageVisible,
    update,
    setUpdate,
    sprint,
    setUpdateTask,
    updateTask,
    userPageVisible
}) {
    const [inputTG, setInputTG] = useState('');
    const [inputETH, setInputETH] = useState('');
    const [currentTask, setCurrentTask] = useState(null);
    const [taskInvites, setTaskInvites] = useState([]);
    const [submissionLink, setSubmissionLink] = useState('');
    const [inviteUsername, setInviteUsername] = useState('');
    const [sentInvites, setSentInvites] = useState([]);
    const [totalSprintPoints, setTotalSprintPoints] = useState(0)
    const [currentSprintPoints, setCurrentSprintPoints] = useState(0)
    const [taskContributors, setTaskContributors] = useState([]);
    const [pastTasks, setPastTasks] = useState([]);
    const [hasLookedForTask, setHasLookedForTask] = useState(false);
    const [level, setLevel] = useState(0)
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (!userId) return;

        if(updateTask)
        {
            fetchCurrentTask({userId, setCurrentTask});
            setUpdateTask(false);
            return;
        }

        if(!hasLookedForTask) 
        {
            fetchCurrentTask({userId, setCurrentTask});
            setHasLookedForTask(true)
        }
        else
        {
            fetchTaskInvites({userId, setTaskInvites});
            fetchSentInvites({currentTask, userId, setSentInvites});
            fetchSprintPoints({userId, setCurrentSprintPoints, setTotalSprintPoints});
            fetchTaskContributors({ userId, taskId: currentTask, setTaskContributors });
            fetchPastTasks({ userId, setPastTasks });
        }

    }, [userId, update, currentTask, hasLookedForTask, updateTask]);


    useEffect(() => {
        const {level: l, progress: p} = calculateLevel(currentSprintPoints);

        setLevel(l)
        setProgress(p);
    }, [currentSprintPoints])

    return (
        <div className={up.Container} id="UserPage">
            {/* <InfoScene topMenuSection = {userPageVisible ? 1 : 0} /> */}
            <h2 onClick={() => setUserPageVisible(false)} className={up.Leave}>⬅️ Return to Contributor Page</h2>
            
            <UserStats 
                level = {level}
                progress = {progress}
                currentSprintPoints={currentSprintPoints}
                totalSprintPoints={totalSprintPoints}
                TGUpdate={TGUpdate}
                setInputTG={setInputTG}
                ETHUpdate={ETHUpdate}
                setInputETH={setInputETH}
                telegram = {telegram}
                inputTG = {inputTG}
                userId = {userId}
                setTelegram = {setTelegram}
                sprint = {sprint}
                ethAddress = {ethAddress}
                setEthAddress = {setEthAddress}
                inputETH = {inputETH}
            />

            <OptionContainer 
                currentTask={currentTask}
                setCurrentTask={setCurrentTask}
                submitTask={submitTask}
                submissionLink={submissionLink}
                setSubmissionLink={setSubmissionLink}
                userId = {userId}
                update = {update}
                setUpdate={setUpdate}
                cancelTask={cancelTask}
                inviteUsername={inviteUsername}
                setInviteUsername={setInviteUsername}
                sendInvite={sendInvite}
                sentInvites={sentInvites}
                submitOnlineLink={submitOnlineLink}
                setUpdateTask = {setUpdateTask}
            />

            <TaskInvites 
                taskInvites = {taskInvites} 
                acceptInvite={acceptInvite} 
                userId = {userId}
                update = {update}
                setUpdate={setUpdate}
            />
            
            <TaskContributors 
                taskContributors={taskContributors}
            />
            
            <div className={up.PastTasksContainer}>
                {pastTasks.length === 0 ? (
                    <p style={{ color: "white" }}>No past tasks found.</p>
                ) : (
                    pastTasks.map((task) => (
                        <div className={up.PastTaskItem} key={`${task.task_id}-${task.claimed_at}`}>
                            <strong>{task.task_title}</strong> <br />
                            Claimed: {new Date(task.claimed_at).toLocaleString()} <br />
                            Status: {task.completed ? "Completed" : "In Progress"}
                        </div>
                    ))
                )}
            </div>


        </div>
    );
}
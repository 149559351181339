export const Titles = [
    `Audit`, // 0
    `Build`, //1
    `Join the ERC-7844 Discussion`, //2
    `Hackathons`,  //3
    `Rewards`, //4
    `About`, //5
    `What are Task Bounties?`, //6
]

export const CPAGECORETEXT = `
    Shape the future of on-chain storage management. Join a thriving community, 
    build a portfolio of contributions, forge new connections, and secure a sizable stake in the project. 
`

export const TextSections = [
    `
    Shape the future of on-chain storage management. Join a thriving community, 
    build a portfolio of contributions, forge new connections, and secure a sizable stake in the project.
   `,

    `
        HoneyBadger's architecture is complex and integrates unique features and design elements that require thorough scrutiny. 
        Auditors are essential in ensuring the security, efficiency, and reliability of HoneyBadger 
        contracts—directly shaping the resilience of ERC-7844 and its adoption across the industry.
\
    `,

    `
        Ultimately, ERC-7844 and HoneyBadger are only as interesting as the projects built with them.  As a builder, you 
        can choose to create utilities to strenghen the ecosystem, or new projects that bolster 
        our collection of case studies and exhibit the high applicability and utility offered by the ERC-7844 
        methodology.  Either way, it's a great opportunity to help bring the ecosystem to life while building your 
        portfolio and expanding your skillset!
    `,

    `Review and engage in discussion about ERC-7844!  We're looking for hard-hitting technical reviews that lead to change, 
    and to cultivate the wider dicussion around our methodology and its applications.
    `,

    `Participate in hackathons and earn USDT and $HBGEN.  Build your portfolio and stand out in the community!`,

    `
    Our objective is to optimize the career-oriented value you derive from each contribution by maximizing  
    visibility and providing you with official recognition as a contributor. We also grant contributors 
    a sizable stake in the project's token supply, ensuring that those who have spent time enhancing the project 
    are its primary stakeholders.
    <br/><br/>
    1/5 of the $HBGEN token supply is allocated to the contributor program, and is released over 4
    community development sprints.  A user's allocation is derived proportionally from the "points" they have 
    earned over a given sprint (25% of points carry over each sprint): 
    <br/><br/>
    user_tokens = (user_points / total_points) * tokens. 
    <br/><br/>
    We maximize visibility by supporting as many projects as we can with official writeups, recognizing all contributors and 
    featuring their work on our website.   We also support projects with token grants (which will be greatly accelerated 
    when the full token ecosystem is launched) and are open to fulfilling unique requests from contributors.  
     
    
    `,

    `ERC-7844 and its extended implementation HoneyBadger represent a new approach to on-chain storage management, 
    offering a standardized, full-service solution that simplifies development, enhances interoperability, and 
    enables in-place storage upgrades.  
    <br/><br/>
    HoneyBadger's guiding question is: what would be the most convenient, powerful, and safe way to manage storage on-chain? 
    The vision is to standardize a new approach that frees developers from manual storage management and creates 
    more room to focus on writing business logic.  

    <br/><br/>
    In addition to facilitating instant access to powerful infrastructure, HoneyBadger and ERC-7844 provide numerous concrete 
    technical advantages.  Our methodology unifies access patterns, enables in-place storage upgrades, guaranteese single-hop 
    storage access, simplifies cross-contract dependencies, and much more.  Get involved and help us shape 
    the future of storage on-chain! 
    `,
    `
        Our Task Bounty program organizes and gamifies the contribution process, making it easy to find/create opportunities and 
        collaborate with others!
    `
]
export const calculateLevel = (totalPoints) => {
    const levelThresholds = [0, 1500, 10000, 30000, 70000, 150000];
    let level = 1;
    let nextLevelPoints = levelThresholds[1];

    for (let i = 1; i < levelThresholds.length; i++) {
        if (totalPoints >= levelThresholds[i]) {
            level = i + 1;
            nextLevelPoints = levelThresholds[i + 1] || levelThresholds[i]; // Cap at max level
        } else {
            break;
        }
    }

    const prevLevelPoints = levelThresholds[level - 2] ?? 0; // Fix: Avoids undefined issues


    // Ensure no division by zero
    const progress = (nextLevelPoints > prevLevelPoints)
        ? ((totalPoints - prevLevelPoints) / (nextLevelPoints - prevLevelPoints)) * 100
        : 100;

    return { level, progress: Math.min(progress, 100) };
};

import { ExpandContainer, CloseContainer } from "../Animations/Section1_Animations"
import styles from '../CSS/Section1.module.css';
import scalablityIcon from '../../../../assets/scalabilityicon.png'
import lock from '../../../../assets/lock2.png'
import efficiency from '../../../../assets/efficiency.gif'
import { Link } from "react-router-dom";

export default function Section1_HTML({timelineRefs})
{
    return(
        <div
            className={styles.Container} 
            id="Section1_Container">
            <div className = {styles.VideoContainer} >
                <iframe
                    style={{
                        width: "100%",
                        maxWidth: (window.innerWidth < 1000) ? "310px" : 
                            (window.innerWidth < 1200) ? "420px" : "560px",
                        height: "315px",
                        borderRadius: "10px",
                        boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)",
                    }}
                    src="https://www.youtube.com/embed/LngHy8ixdvs?start=32"
                ></iframe>
                <div className = {styles.VideoTextContainer}>

                    <h2 className = "titleText">
                        Learn the Fundamentals
                    </h2>

                    <p className={"opensans"} style = {{margin: 0, padding: 0, marginTop: "-10px"}}>
                        What is HoneyBadger, 
                        what problems does it solve, and how can it be 
                        applied in practice?
                    </p>
                    <Link to = "docs" style = {{textDecoration: 'none', width: "100%",
                    marginTop: "60px"}}>
                        <button
                        style = {{width: "100%"}}
                        className = "glowingButton">Read the Docs</button>
                    </Link>

                </div>
            </div>
            <h2 className="titleText" style = {{width: "560px", color: 'white', textAlign: 'center'}}>

            </h2>
            <div className={styles.TextContainer} id="Section1_TextContainer">


                <h2 className="titleText" id="Section1_Header">
                    Cut Costs, Upgrade Easily, and Build Faster Than Ever
                </h2>
                <p className={"opensans"} id="Section1_Paragraph">
                HoneyBadger gives your project a flexible structure that perfectly balances decentralization and control, 
                allowing you to build business logic that <i>brings your vision to life</i>
                &nbsp; -- and seamlessly evolve your system as new market demands emerge.
                </p>
            </div>

            <span className={styles.AxiomContainer} id = "MainAxiomContainer">

                <div className={styles.Axiom} style = {{zIndex: 2}} id = "Axiom1"
                onMouseEnter={() => {ExpandContainer("#Axiom1", "#AxiomContainer1", "#AxiomP1", "#AxiomP1LongDescription", 1, timelineRefs)}} 
                onMouseLeave={() => {CloseContainer("#Axiom1", "#AxiomContainer1", "#AxiomP1", "#AxiomP1LongDescription", 1, timelineRefs)}}>
                    <div className={styles.AxiomIcon} id = "AxiomContainer1">
                        <img  src = {scalablityIcon} />
                    </div>
                    <p id = "AxiomP1">Unparalleled Adaptability</p> 
                    <p id = "AxiomP1LongDescription" className={`${styles.LongDescription} opensans`}>
                    HoneyBadger enables unparalleled smart contract adaptability 
                    with seamless upgrade integration, 
                    ensuring scalability and vulnerability resilience without rework or compromise.
                    </p>
                </div>

                <div 
                    className={styles.Axiom} 
                    style = {{zIndex: 3}} 
                    id = "Axiom2"
                    onMouseEnter={() => {ExpandContainer("#Axiom2", "#AxiomContainer2", "#AxiomP2", "#AxiomP2LongDescription", 2, timelineRefs)}} 
                    onMouseLeave={() => {CloseContainer("#Axiom2", "#AxiomContainer2", "#AxiomP2", "#AxiomP2LongDescription", 2, timelineRefs)}}
                    >
                    <div className={styles.AxiomIcon} 
                        id = "AxiomContainer2">
                        <img src = {lock} />
                    </div>
                    <p id = "AxiomP2">Robust Security</p> 
                    <p id = "AxiomP2LongDescription" className={`${styles.LongDescription} opensans`}>

                    HoneyBadger guarantees full system integrity through add-ons that enhance 
                    decentralization, 
                    empowering developers to adapt their systems without 
                    degrading user confidence.

                    </p>
                </div>

                { window.innerWidth > 1100 && (

                <div 
                    className={styles.Axiom} 
                    style = {{zIndex: 1}} 
                    id = "Axiom3"
                    onMouseEnter={() => {ExpandContainer("#Axiom3", "#AxiomContainer3", "#AxiomP3", "#AxiomP3LongDescription", 3, timelineRefs)}} 
                    onMouseLeave={() => {CloseContainer("#Axiom3", "#AxiomContainer3", "#AxiomP3", "#AxiomP3LongDescription", 3, timelineRefs)}}>
                    <div className={styles.AxiomIcon} 
                        id = "AxiomContainer3">
                        <img 
                            className = {styles.ExtraBigIcon} 
                            src = {efficiency}/>
                    </div>
                    <p id = "AxiomP3">Powerful Features</p> 
                    <p id = "AxiomP3LongDescription" className={`${styles.LongDescription} opensans`}>

                        HoneyBadger leverages an assembly-heavy implementation to minimize the cost of 
                        its operations, while providing powerful gas-saving capabilities such as batch operations.  
                        

                    </p>
                </div>

                )}

            </span>

        </div>
    )
}